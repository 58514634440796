import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CssBaseline, Drawer, List, Toolbar, Typography, Box, ListItemButton, Button, Menu } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Securitycenter from "../settings/Securitycenter";
import { RootContext } from "../context/root-provider";
// import Services from "../services/hservice";
// import StepData from "../services/step-data";
import ConfirmDialogComponent from "../components/ConfirmDialogComponent";
import "./setting.scss";

const styles = {
  profileStyles: {
    "& form": {
      width: "90%",
    },
  },
  toolbarStyle: { marginLeft: "240px" },
  drawerStyle: { background: "none" },
  mobileDrawerStyle: { background: "white", height: "100%", top: "3" },
  pageStyle: { display: "flex" },
  navDrawerStyle: { width: "240px", flexShrink: 0, zIndex: 0 },
  drawerPaperStyle: {
    width: "240px",
    background: "white",
    zIndex: 0,
    marginTop: "64px",
  },
  mainStyle: { flexGrow: 1 },
  mobileDropdownContainerStyle: { textAlign: "left", marginTop: "3px" },
  mobileDropdownTextStyle: {
    color: "black",
    fontSize: 17,
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      color: "common.black",
    },
    paddingRight: "0px",
  },
  arrowDropdownStyle: {
    marginTop: "1px",
    marginLeft: "-2px",
  },
  dropDownMenuStyle: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [values, setValues] = useState({
    security: true,
    restrictions: false,
    linkedaccounts: false,
    notifications: false,
    test_acct: false,
    bacctId: null,
  });
  const [width, setWindowWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMemo(() => width <= 1023, [width]);

  const { setSelectedTab } = useContext(RootContext);

  useEffect(() => {
    setSelectedTab(null);
  }, [setSelectedTab]);

  const updateDimensions = useCallback(() => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }, []);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  const locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    const run = async () => {
      const test_acct = false;
      if (locationRef.current.hash === "#notifications") {
        setValues((v) => {
          return {
            ...v,
            notifications: true,
            security: false,
            test_acct: test_acct,
          };
        });
      } else {
        setValues((v) => {
          return {
            ...v,
            security: true,
            test_acct: test_acct,
          };
        });
      }
    };
    run();
  }, []);

  const sideMenuHandle = useCallback(
    (menuItem) => {
      location.state = "";
      navigate(`/settings#${menuItem}`);
      setValues((v) => {
        return { ...v, security: menuItem === "security", notifications: menuItem === "notifications" };
      });
    },
    [location, navigate]
  );

  const settingsMenuList = useMemo(
    () => (
      <List>
        <ListItemButton
          onClick={() => {
            sideMenuHandle("security");
            setAnchorEl(null);
          }}
          selected={values.security}
          id="lst_item_mb_security_center">
          Security center
        </ListItemButton>

        {/* <ListItemButton
          onClick={() => {
            sideMenuHandle("notifications");
            handleClose();
          }}
          className={values.notifications ? "setting_active" : "setting_test_user"}
          id="lst_item_mb_notification">
          Notifications
        </ListItemButton> */}
      </List>
    ),
    [sideMenuHandle, values]
  );

  const drawer = useMemo(() => <div style={styles.drawerStyle}>{settingsMenuList}</div>, [settingsMenuList]);

  const mobileDropdownMenu = useMemo(
    () => (
      <>
        <div style={styles.mobileDropdownContainerStyle}>
          <Button
            variant="text"
            size="large"
            sx={styles.mobileDropdownTextStyle}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            id="settings_menu_button">
            Settings
            <ArrowDropDownIcon aria-controls="simple-menu" aria-haspopup="true" sx={styles.arrowDropdownStyle} />
          </Button>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            elevation: 0,
            sx: styles.dropDownMenuStyle,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          {settingsMenuList}
        </Menu>
      </>
    ),
    [anchorEl, settingsMenuList]
  );

  const confirmToBack = useCallback(
    (mName) => {
      setDialogOpen(false);
      if (mName !== "") {
        sideMenuHandle(mName);
        setMenuName("");
      }
    },
    [sideMenuHandle]
  );

  const confirmDialog = useMemo(
    () => (
      <>
        <ConfirmDialogComponent
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          confirmToBack={confirmToBack}
          menuName={menuName}>
          <p>
            If you navigate away from this form page, you will lose changes that are pending. Select OK to continue
            anyway or select CANCEL to resume editing and to submit when done.
          </p>
        </ConfirmDialogComponent>
      </>
    ),
    [confirmToBack, dialogOpen, menuName]
  );

  const notificationsPage = useMemo(
    () => (
      <>
        {values.test_acct && (values.linkedaccounts || values.notifications) && (
          <span style={{ backgroundColor: "#ffff00" }}>(For TEST Accounts only)</span>
        )}
      </>
    ),
    [values]
  );

  const mobilePage = useMemo(
    () => (
      <>
        {mobileDropdownMenu}
        <div style={styles.pageStyle}>
          <CssBaseline />
          <main style={styles.mainStyle}>
            {notificationsPage}
            <div hidden={!values.security}>
              <Securitycenter />
            </div>
          </main>
        </div>
        {confirmDialog}
      </>
    ),
    [mobileDropdownMenu, confirmDialog, notificationsPage, values.security]
  );

  const page = useMemo(
    () => (
      <>
        <Toolbar sx={styles.toolbarStyle}>
          <Typography variant="h6">Settings </Typography>
        </Toolbar>
        <div style={styles.pageStyle}>
          <CssBaseline />
          <nav style={styles.navDrawerStyle} aria-label="mailbox folders">
            <Drawer PaperProps={{ sx: styles.drawerPaperStyle }} variant="permanent" open>
              {drawer}
            </Drawer>
          </nav>
          <main style={styles.mainStyle}>
            {notificationsPage}
            <div hidden={!values.security}>
              <Securitycenter />
            </div>
          </main>
        </div>
        {confirmDialog}
      </>
    ),
    [values, notificationsPage, confirmDialog, drawer]
  );

  const res = useMemo(
    () => (
      <Box className="settings" sx={styles.profileStyles}>
        {isMobile ? <div>{mobilePage}</div> : <div>{page}</div>}
      </Box>
    ),
    [isMobile, mobilePage, page]
  );

  return res;
};

export default Settings;
