import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
} from "@mui/material";
import Taps from "../components/Taps";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CalendarImage from "../assets/calendar_image.png";
import CreateTapModal from "../components/CreateTapModal";
import { useSnackbar } from "../components/AlertNotification";
import { useMeetingActions, useTapActions, useFilter } from "../services/hooks";
import "./Timeline.scss";

const styles = {
  container: {
    padding: "32px 64px",
    paddingBottom: "96px",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    color: "#051817",
    fontWeight: 700,
    fontSize: "28px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    marginBottom: "32px",
  },
  pageActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  accordionControl: {
    margin: "10px 5px",
    marginLeft: "0",
    textTransform: "none",
  },
  meeting: {
    ".MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
    },
  },
  bullet: {
    width: "8px",
    height: "8px",
    background: "#009698",
    borderRadius: "4px",
    marginRight: "12px",
  },
  docTitle: {
    marginRight: "12px",
    fontWeight: 700,
    fontSize: "24px",
    color: "#051817",
    textDecoration: "none",
    "&:hover": {
      color: "#009698",
    },
  },
  docDate: {
    fontSize: "12px",
    color: "#051817",
    alignSelf: "flex-end",
    lineHeight: "27px",
    pointerEvents: "none",
    marginRight: "40px",
  },
  actions: {
    display: "flex",
    alignSelf: "flex-end",
  },
  actionButton: {
    color: "#009698",
    pointerEvents: "all",
  },
  purpose: {
    marginBottom: "10px",
    fontSize: "14px",
    lineHeight: "16px",
  },
  emptyHeader: {
    fontSize: "36px",
    fontWeight: 700,
    margin: "20px 0",
  },
  emptySubheading: {
    fontSize: "24px",
    fontWeight: 700,
    margin: "20px 0 40px 0",
  },
  noDataHeaderStyle: {
    fontSize: "36px",
    lineHeight: "44px",
    color: "#051817",
    fontWeight: "700",
  },
  calendarImageContainerStyle: { justifyContent: "center", textAlign: "center", display: "flex", marginTop: "-40px" },
  calendarImageStyle: { maxWidth: "400px" },
  noDataSubtitleStyle: {
    fontSize: "24px",
    lineHeight: "28px",
    color: "#051817",
    fontWeight: "700",
    float: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "-45px",
  },
  zoomButtonStyle: {
    backgroundColor: "rgba(0, 150, 152, 1)",
    color: "white",
    marginTop: "35px",
    "&:hover": { backgroundColor: "rgb(0 96 98)" },
  },
};

const Timeline = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [createTapModalOpen, setCreateTapModalOpen] = useState(false);
  const [creatingDoc, setCreatingDoc] = useState();
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const navigate = useNavigate();

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();
  const { filter, filterEl } = useFilter("Filter Meetings");

  useEffect(() => {
    setSelectedTab("meetings");
  }, [setSelectedTab]);

  // document action handlers

  const update = useCallback(
    (updates, id) => {
      if (!updates) {
        showSnackbar("Unable to update meeting", "error");
      } else {
        setMeetings((meetings) => {
          const meetingsCopy = [...meetings];
          const updatedDocIndex = meetingsCopy.findIndex((meeting) => meeting.id === id);
          Object.assign(meetingsCopy[updatedDocIndex], updates);
          if (!expandedAccordions[updatedDocIndex] && updates.purpose) {
            setExpandedAccordions((expandedAccordions) =>
              expandedAccordions.map((a, i) => (i === updatedDocIndex ? true : a))
            );
          }
          return meetingsCopy;
        });
        showSnackbar("Meeting updated", "success");
      }
    },
    [expandedAccordions, showSnackbar]
  );

  const { setEditingMeeting, editMeetingDialog } = useMeetingActions(update);

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setMeetings((meetings) => {
          const meetingsCopy = [...meetings];
          const docIndex = meetingsCopy.findIndex((meeting) => meeting.taps?.find((tap) => tap.tapId === tapId));
          const tapIndex = meetingsCopy[docIndex].taps?.findIndex((tap) => tap.tapId === tapId);
          if (tapIndex >= 0) {
            meetingsCopy[docIndex].taps?.splice(tapIndex, 1);
            meetingsCopy[docIndex] = { ...meetingsCopy[docIndex] };
          }
          return meetingsCopy;
        });
        showSnackbar("Tapestry deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(
    (tapId, title) => {
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap]
  );

  // initiate document actions

  const editMeeting = useCallback(
    (meeting) => {
      setEditingMeeting(meeting);
    },
    [setEditingMeeting]
  );

  const fetchMeetings = useCallback(
    async (showLoading = true) => {
      if (userInfo?.username) {
        if (showLoading) {
          setLoading(true);
        }
        const data = await AppBackService.getUserTimeline(userInfo.username);
        if (!data || data.error) {
          setError(true);
        } else {
          if (data.meetings?.length) {
            data.meetings.forEach((meeting) => {
              meeting.startTime = dayjs(isNaN(+meeting.startTime) ? meeting.startTime : +meeting.startTime);
            });
            setExpandedAccordions(data.meetings.map((meeting) => !(!meeting.taps?.length && !meeting.purpose)));
            setMeetings(data.meetings);
          }
        }
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [userInfo]
  );

  // init, fetch meetings
  useEffect(() => {
    if (userInfo?.username && !loggingIn) {
      fetchMeetings();
    }
  }, [fetchMeetings, loggingIn, meetingsSynced, userInfo]);

  const onCreatedTap = useCallback(
    (created) => {
      if (created.type === "submit") {
        if (created.success) {
          showSnackbar("Creating Tapestry...", "info");
        } else {
          showSnackbar("Unable to create Tapestry", "error");
        }
      } else if (created.type === "resolve" && created.success) {
        showSnackbar(`Tapestry "${created.tap?.title}" created`, "success", "View", () => {
          navigate("/tapestry/" + created.tap?.tapId);
        });
        fetchMeetings();
      }
    },
    [fetchMeetings, navigate, showSnackbar]
  );

  const expandAll = useCallback(() => {
    setExpandedAccordions(meetings.map((meeting) => !(!meeting.taps?.length && !meeting.purpose)));
  }, [meetings]);

  const collapseAll = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => expandedAccordions.map(() => false));
  }, []);

  // elements

  const meetingsSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Box sx={{ marginBottom: "20px", width: "870px", maxWidth: "calc(100vw - 128px)" }} key={i}>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Box>
      )),
    []
  );

  const meetingsEls = useMemo(
    () =>
      meetings
        .filter(
          (meeting) =>
            !filter ||
            meeting.title.toLowerCase().includes(filter.toLowerCase()) ||
            meeting.startTime.format("llll").toLowerCase().includes(filter.toLowerCase())
        )
        .map((meeting, i) => (
          <MeetingAccordion
            key={meeting.docId || i}
            meeting={meeting}
            index={i}
            editMeeting={editMeeting}
            setCreateTapModalOpen={setCreateTapModalOpen}
            setCreatingDoc={setCreatingDoc}
            deleteTap={deleteTap}
            expanded={expandedAccordions[i]}
            setExpandedAccordions={setExpandedAccordions}
          />
        )),
    [meetings, filter, editMeeting, deleteTap, expandedAccordions]
  );

  const errorEl = useMemo(
    () => (
      <Box className="error">
        <ErrorIcon />
      </Box>
    ),
    []
  );

  const noDataPage = useMemo(
    () => (
      <>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={0} sm={2} md={3} lg={3} />
          <Grid item xs={12} sm={8} md={6} lg={6}>
            <Typography sx={styles.noDataHeaderStyle}>No Meetings Found</Typography>
            <div style={styles.calendarImageContainerStyle}>
              <img src={CalendarImage} style={styles.calendarImageStyle} alt="calendar" />
            </div>
            <Typography sx={styles.noDataSubtitleStyle}>
              Schedule a meeting in Zoom to see your upcoming meetings here
            </Typography>
            <Button component={Link} href="zoomus://" sx={styles.zoomButtonStyle}>
              Open Zoom
            </Button>
          </Grid>
          <Grid item xs={0} sm={2} md={3} lg={3} />
        </Grid>
      </>
    ),
    []
  );

  return error ? (
    errorEl
  ) : (
    <Box sx={{ ...styles.container, textAlign: meetings.length ? "left" : "center" }}>
      {meetings.length || loading ? (
        <Box>
          <Box sx={styles.headerContainer}>
            <Typography sx={styles.header}>Meetings</Typography>
            <Box sx={{ marginLeft: "16px" }}>{filterEl}</Box>
          </Box>
          {expandedAccordions.length ? (
            <Box sx={styles.pageActions}>
              <Button
                sx={styles.accordionControl}
                size="small"
                onClick={expandAll}
                disabled={expandedAccordions.every((a, i) => a || (!meetings[i].taps?.length && !meetings[i].purpose))}>
                Expand All
              </Button>
              <Button
                sx={styles.accordionControl}
                size="small"
                onClick={collapseAll}
                disabled={expandedAccordions.every((a) => !a)}>
                Collapse All
              </Button>
            </Box>
          ) : null}
          <Box sx={{ maxWidth: "1080px" }}>{loading ? meetingsSkeleton : meetingsEls}</Box>
          <CreateTapModal
            open={createTapModalOpen}
            setOpen={setCreateTapModalOpen}
            doc={creatingDoc}
            onCreated={onCreatedTap}
          />
        </Box>
      ) : (
        noDataPage
      )}
      {editMeetingDialog}
      {deleteTapConfirm}
      {snackbarEl}
    </Box>
  );
};

const MeetingAccordion = ({
  meeting,
  index,
  editMeeting,
  setCreateTapModalOpen,
  setCreatingDoc,
  deleteTap,
  expanded,
  setExpandedAccordions,
}) => {
  const handleChange = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => {
      const expandedAccordionsCopy = [...expandedAccordions];
      expandedAccordionsCopy[index] = !expandedAccordionsCopy[index];
      return expandedAccordionsCopy;
    });
  }, [index, setExpandedAccordions]);

  return (
    <Accordion
      sx={{ pointerEvents: !meeting.taps?.length ? "none" : "all", backgroundColor: "#F1F9FB", minWidth: "870px" }}
      expanded={expanded}
      onChange={handleChange}>
      <AccordionSummary
        sx={styles.meeting}
        expandIcon={<ExpandMoreIcon sx={{ visibility: !meeting.taps?.length ? "hidden" : "visible" }} />}
        aria-controls={`meeting${index}-content`}
        id={`meeting${index}-header`}>
        <Box sx={styles.bullet}></Box>
        <Typography sx={styles.docTitle}>{meeting.title}</Typography>
        <Typography sx={styles.docDate}>{meeting.startTime.format("llll")}</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={styles.actions}>
          <IconButton
            sx={{ ...styles.actionButton, marginRight: !meeting.docId ? "0" : "10px" }}
            title="Edit title"
            aria-label="Edit title"
            onClick={(e) => {
              e.stopPropagation();
              editMeeting(meeting);
            }}>
            <EditIcon />
          </IconButton>
          {meeting.docId && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#009698",
                marginRight: "10px",
                textTransform: "none",
                fontWeight: 600,
                pointerEvents: "all",
                "&:hover": {
                  background: "#006062",
                },
              }}
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                setCreatingDoc(meeting);
                setCreateTapModalOpen(true);
              }}>
              Create Tapestry
            </Button>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {meeting.purpose && <Typography sx={styles.purpose}>{meeting.purpose}</Typography>}
          {meeting.taps && <Taps taps={meeting.taps} deleteTap={deleteTap} />}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
MeetingAccordion.propTypes = {
  meeting: PropTypes.object,
  index: PropTypes.number,
  deleteDoc: PropTypes.func,
  editMeeting: PropTypes.func,
  setCreateTapModalOpen: PropTypes.func,
  setCreatingDoc: PropTypes.func,
  deleteTap: PropTypes.func,
  expanded: PropTypes.bool,
  setExpandedAccordions: PropTypes.func,
};

export default Timeline;
