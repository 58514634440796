import React, { useEffect, useState, useCallback, useMemo } from "react";
import AlertNotification from "../components/AlertNotification";
import { Auth } from "aws-amplify";
import { Button, TextField, IconButton, InputAdornment, Typography, Link } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AppBackService from "../services/appback";

const styles = {
  textBoxStyle: { width: "70%", marginBottom: "25px", marginTop: "-15px" },
  submitButtonStyle: {
    backgroundColor: "rgba(0, 150, 152, 1)",
    width: "70%",
    marginTop: "-15px !important",
    "&:hover": { backgroundColor: "rgb(0, 96, 98)" },
    marginBottom: "8px",
  },
  headerStyle: { marginBottom: "0px" },
  currentEmailHeaderTextStyle: {
    textAlign: "left",
    marginTop: "-15px",
    marginLeft: "65px",
    fontFamily: "Nunito, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    color: "rgba(0, 0, 0, 0.54)",
  },
  currentEmailTextStyle: {
    textAlign: "left",
    marginBottom: "35px",
    marginLeft: "65px",
    marginTop: "5px",
    fontWeight: "500",
  },
  checkEmailTextStyle: { fontSize: 18, marginTop: "-15px" },
  authCodePromptTextStyle: { fontSize: 15, marginBottom: "4px" },
  authCodeTextFieldStyle: { width: "70%", marginBottom: "10px" },
  formStyle: { marginLeft: "42px", width: "80%" },
};

const ChangeEmail = ({ userData }) => {
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showCEPassword, setShowCEPassword] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertOpen = useCallback(() => setAlertOpen(true), []);

  useEffect(() => {
    if (userData) {
      setConfirmEmail(!userData?.attributes?.email_verified);
      setNewEmail(userData?.attributes?.email);
      setOldEmail(userData?.attributes?.email);
    }
  }, [userData]);

  const handleChangeEmail = useCallback(
    (model, { setErrors, setStatus }) => {
      let password = model.password;
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, password, password);
        })
        .then(async () => {
          if (model.new_email?.toLocaleLowerCase() === oldEmail?.toLocaleLowerCase()) {
            await Auth.verifyCurrentUserAttribute("email");
          }
          return Auth.updateUserAttributes(userData, {
            email: model.new_email?.toLocaleLowerCase(),
          })
            .then(async () => {
              setConfirmEmail(true);
              setNewEmail(model.new_email.toLocaleLowerCase());
              await AppBackService.updateUserEmail(userData.username, model.new_email.toLocaleLowerCase());
            })
            .catch((err) => {
              setErrors({ api: err?.message });
              setStatus({});
            });
        })
        .catch((err) => {
          setErrors({ api: err?.message });
          setStatus({});
        });
    },
    [oldEmail, userData]
  );

  const handleReverify = useCallback(() => {
    setConfirmEmail(false);
  }, []);

  const handleAuthCodeSubmit = useCallback(
    (values, { setErrors, resetForm, setStatus }) => {
      Auth.verifyCurrentUserAttributeSubmit("email", values.confirm_auth_code)
        .then(() => {
          setOldEmail(newEmail);
          setConfirmEmail(false);
          const status = { message: "successfully changed email" };
          handleAlertOpen();
          setStatus(status);
          resetForm({ status });
        })
        .catch((err) => {
          setErrors({ api: err?.message });
          setStatus({});
        });
    },
    [handleAlertOpen, newEmail]
  );

  const stopMouseEvent = useCallback((event) => {
    event.preventDefault();
  }, []);

  const res = useMemo(
    () => (
      <>
        <h3 data-testid="change-email-title" style={styles.headerStyle}>
          Change Email
        </h3>
        <>
          <Formik
            initialValues={{
              new_email: "",
              password: "",
              confirm_auth_code: "",
            }}
            validationSchema={yup.object().shape({
              new_email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
              password: yup.string("Enter your password").required("Password is required"),
            })}
            onSubmit={(values, formikBag) => {
              handleChangeEmail(values, formikBag);
            }}>
            {({ values, errors, touched, handleChange, setErrors, resetForm, setStatus, status }) => (
              <>
                <p className="error">{errors?.api}</p>
                <p className="success">{status?.message}</p>
                {!confirmEmail && (
                  <>
                    <Typography sx={styles.currentEmailHeaderTextStyle}>Current Email:</Typography>
                    <Typography sx={styles.currentEmailTextStyle}>{oldEmail}</Typography>
                    <Form>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="newemail"
                        name="new_email"
                        autoComplete="email"
                        label="New Email Address"
                        value={values.new_email}
                        onChange={handleChange}
                        error={touched.new_email && Boolean(errors.new_email)}
                        helperText={touched.new_email && errors.new_email}
                        sx={styles.textBoxStyle}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="password"
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type={showCEPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowCEPassword(!showCEPassword)}
                                onMouseDown={stopMouseEvent}>
                                {showCEPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={styles.textBoxStyle}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        id="change_email_submit_button"
                        sx={styles.submitButtonStyle}>
                        Submit
                      </Button>
                    </Form>
                  </>
                )}

                {confirmEmail && (
                  <>
                    <Form style={styles.formStyle}>
                      <p className="" style={styles.checkEmailTextStyle}>
                        Please check your {newEmail.toLocaleLowerCase()} email for a verification code.
                      </p>
                      <p style={styles.authCodePromptTextStyle}>Enter verification code here</p>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="confirm_auth_code"
                        name="confirm_auth_code"
                        label="Auth Code"
                        autoComplete="one-time-code"
                        value={values.confirm_auth_code || ""}
                        sx={styles.authCodeTextFieldStyle}
                        onChange={handleChange}
                        error={touched.confirm_auth_code && Boolean(errors.confirm_auth_code)}
                        helperText={touched.confirm_auth_code && errors.confirm_auth_code}
                      />
                      <Button
                        variant="contained"
                        style={styles.submitButtonStyle}
                        onClick={() => {
                          handleAuthCodeSubmit(values, {
                            setErrors,
                            resetForm,
                            setStatus,
                          });
                        }}
                        id="change_email_verify_submit_button">
                        Submit
                      </Button>
                    </Form>
                    <Link variant="contained" onClick={handleReverify} id="change_email_send_button">
                      Resend Email
                    </Link>
                  </>
                )}
              </>
            )}
          </Formik>
        </>
        <AlertNotification
          message="New user settings have been saved."
          severity={null}
          open={alertOpen}
          setOpen={setAlertOpen}
        />
      </>
    ),
    [
      alertOpen,
      confirmEmail,
      handleAuthCodeSubmit,
      handleChangeEmail,
      newEmail,
      oldEmail,
      showCEPassword,
      handleReverify,
      stopMouseEvent,
    ]
  );

  return res;
};

export default ChangeEmail;
