import { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";

export const SESSION_TIME_OUT = 60;
const COUNTER = 59;
const DEBOUNCE = 1000 * 60;
const TIMEOUT = SESSION_TIME_OUT * 30 * 1000;
const PROMPT_TIMEOUT = (COUNTER + 1) * 1000;

const AutoLogout = (logout) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [count, setCount] = useState(COUNTER);
  const onIdle = useCallback(() => {
    setDialogOpen(false);
    logout();
  }, [logout]);

  const onPrompt = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const onAction = useCallback(() => {
    localStorage.setItem("activeTime", +new Date());
  }, []);

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    timeout: TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
    onPrompt,
    crossTab: true,
    debounce: DEBOUNCE,
    onAction,
  });
  useEffect(() => {
    if (dialogOpen) {
      const interval = setInterval(() => {
        setCount(Math.floor(getRemainingTime() / 1000));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dialogOpen, getRemainingTime]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    setCount(COUNTER);
    activate();
  }, [activate]);

  const res = useMemo(
    () => (
      <>
        <Dialog open={dialogOpen} aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be automatically logged out in {count} seconds.
              <br /> <br />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1685d3", color: "white" }}
                onClick={handleDialogClose}
                id="stay_logged_button">
                Stay Logged In
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    ),
    [dialogOpen, count, handleDialogClose]
  );

  return res;
};

export default AutoLogout;
