import { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useRoutes } from "../app/routes";
import PuzzleFaces from "../assets/puzzle_faces.png";
import VisualFace from "../assets/visual_face.png";
import IdeaFace from "../assets/idea_face.png";

const styles = {
  backgroundStyle: {
    height: "100vh",
    overflowY: "auto",
    paddingTop: "50px",
    boxSizing: "border-box",
    background: "linear-gradient(#009698, #455c70)",
  },
  scrollBoxStyle: {
    height: "100vh",
    overflowY: "scroll",
  },
  loginBoxStyle: {
    margin: "0 auto",
    marginBottom: "60px",
    textAlign: "center",
    isolation: "isolate",
    borderRadius: "10px",
    background: "#F1F9FB",
    width: "60%",
    minWidth: "780px",
  },
  innerBoxStyle: {
    paddingTop: "38px",
    paddingBottom: "38px",
  },
  welcomeComponentStyle: { borderRight: "2px solid", borderColor: "rgba(69, 92, 112, 1)" },
  titleTextStyle: { fontWeight: 700, fontSize: "24px", marginTop: "30px", color: "rgba(5, 24, 23, 1)" },
  subtitleTextStyle: {
    marginLeft: "40px",
    marginRight: "40px",
    marginTop: "10px",
    fontSize: "16px",
  },
  welcomeGridStyle: { marginTop: "78px" },
  welcomeRowStyle: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    height: "141px",
    width: "100%",
    marginTop: "-50px",
    boxSizing: "border-box",
  },
  welcomeTextStyle: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(5, 24, 23, 1)",
    textAlign: "left",
  },
  overlappingImagesConatinerStyle: { position: "relative" },
  overlappingImageStyle: { width: "8vw" },
  puzzleFacesStyle: { zIndex: 0, top: 0, left: 30 },
  visualFaceStyle: { zIndex: 1, top: 90, left: 80 },
  ideaFaceStyle: { zIndex: 2, top: 180, left: 30 },
  puzzleTextStyle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(5, 24, 23, 1)",
    marginTop: "45px",
    marginLeft: "-15px",
    paddingRight: "40px",
    textAlign: "left",
  },
  visualTextStyle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(5, 24, 23, 1)",
    marginTop: "55px",
    textAlign: "left",
    marginLeft: "30px",
    paddingRight: "10px",
  },
  ideaTextStyle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(5, 24, 23, 1)",
    marginTop: "60px",
    marginLeft: "-20px",
    textAlign: "left",
    marginBottom: "50px",
  },
};

const Identity = () => {
  const { unAuthRoutes } = useRoutes();
  const welcomeComponent = useMemo(
    () => (
      <>
        <Typography sx={styles.titleTextStyle}>Welcome to Tapestry!</Typography>
        <Typography sx={styles.subtitleTextStyle}>Instantly create a visual story from your conversation</Typography>
        <div style={styles.welcomeGridStyle}>
          <div style={styles.welcomeRowStyle}>
            <img
              src={PuzzleFaces}
              alt="puzzle faces"
              style={{ ...styles.overlappingImageStyle, ...styles.puzzleFacesStyle }}
            />
            <Typography sx={styles.welcomeTextStyle}>Shared vision brings people together</Typography>
          </div>
          <div style={{ ...styles.welcomeRowStyle, paddingLeft: 92 }}>
            <img
              src={VisualFace}
              alt="seeing face"
              style={{ ...styles.overlappingImageStyle, ...styles.visualFaceStyle }}
            />
            <Typography sx={{ ...styles.welcomeTextStyle, maxWidth: "180px" }}>
              Words come alive with visual metaphors
            </Typography>
          </div>
          <div style={styles.welcomeRowStyle}>
            <img src={IdeaFace} alt="idea face" style={{ ...styles.overlappingImageStyle, ...styles.ideaFaceStyle }} />
            <Typography sx={styles.welcomeTextStyle}>Art makes ideas stick</Typography>
          </div>
        </div>
      </>
    ),
    []
  );

  const res = useMemo(() => {
    return window.location.href.indexOf("early") > -1 ? (
      <div style={styles.scrollBoxStyle}>
        <Box sx={styles.innerBoxStyle}>
          <img src="/logo_with_text.png" style={styles.logoImageStyle} alt="Tapestry AI logo" />
          {unAuthRoutes()}
        </Box>
      </div>
    ) : (
      <div style={styles.backgroundStyle}>
        <Box sx={styles.loginBoxStyle}>
          <Box sx={styles.innerBoxStyle}>
            <Grid container>
              <Grid item md={6} lg={6} sx={styles.welcomeComponentStyle}>
                {welcomeComponent}
              </Grid>
              <Grid item md={6} lg={6}>
                <img src="/logo_with_text.png" alt="TapestryAI logo" />
                {unAuthRoutes()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    );
  }, [unAuthRoutes, welcomeComponent]);
  return res;
};

export default Identity;
