import React, { useState, useEffect, createContext } from "react";
import { Auth } from "aws-amplify";
import AppBackService from "../services/appback";

export const RootContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  authStatus: false,
  setAuthStatus: () => {},
  accountStatus: false,
  setAccountStatus: () => {},
  accountStatusName: "",
  setAccountStatusName: () => {},
  accountLength: 0,
  setAccountLength: () => {},
  hasTimeOut: false,
  setHasTimeOut: () => {},
});

let debounce;

const RootProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasTimeOut, setHasTimeOut] = useState(false);
  const [authStatus, setAuthStatus] = useState(null);
  const [authAttrs, setAuthAttrs] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [authCode, setAuthCode] = useState(new URLSearchParams(window.location.search).get("code"));
  const [inZoomClient, setInZoomClient] = useState(false);
  const [meetingsSynced, setMeetingsSynced] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const [accountStatusName, setAccountStatusName] = useState("");
  const [accountLength, setAccountLength] = useState(0);

  useEffect(() => {
    const handleGetDetails = async () => {
      try {
        const cogUser = await Auth.currentAuthenticatedUser();
        setUserInfo(cogUser);

        setLoggingIn(true);
        const User = await AppBackService.getUser(cogUser.username);

        setLoggingIn(false);
        // Create the User and Account DB objects if needed
        if (!User.id) {
          console.log("Creating User");

          //Check to see if the user has a saved zoom authorziation code indicating they came via zoom oauth
          const ZoomAuth = await AppBackService.getZoomAuthCode(cogUser.username);

          await AppBackService.insertUser(ZoomAuth.auth.code ? "zoom" : "web");
        }

        setAuthAttrs(cogUser.attributes);
        setAuthStatus(true);
      } catch (e) {
        setAuthStatus(false);
      }
    };
    if (!debounce) {
      handleGetDetails();
    }
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      debounce = null;
    }, 1000);
  }, [authCode, authStatus]);

  return (
    <RootContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        authStatus,
        setAuthStatus,
        authAttrs,
        setAuthAttrs,
        userInfo,
        setUserInfo,
        authCode,
        setAuthCode,
        accountStatusName,
        setAccountStatusName,
        accountLength,
        setAccountLength,
        hasTimeOut,
        setHasTimeOut,
        inZoomClient,
        setInZoomClient,
        meetingsSynced,
        setMeetingsSynced,
        loggingIn,
        selectedTab,
        setSelectedTab,
        notifications,
        setNotifications,
      }}>
      {props.children}
    </RootContext.Provider>
  );
};

export default RootProvider;
