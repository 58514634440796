import { Auth } from "aws-amplify";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { cogData } from "./cognito";
import { changeCase } from "./utilities";

const API_URL = `${process.env.REACT_APP_APPBACK_URL}` || "https://appback-dev.tapestryai.com";
//console.log("API URL: " + API_URL);
const handleError = (status, body) => {
  console.log("Error:", status, body);
};

const AppBackService = {
  axiosExcNoAauth: async (type, url, params, data) => {
    try {
      const resp = await axios({
        method: `${type}`,
        url: API_URL + url,
        headers: {},
        data,
        params,
      });
      resp.data =
        resp.data.length && resp.data.map
          ? changeCase.toCamelfromSnakeList(resp.data)
          : changeCase.toCamelfromSnakeArr(resp.data);
      return resp.data;
    } catch (error) {
      const { status, data } = error.response ?? error.message;
      handleError(status, { msg: data?.message });
      return { error, status, data, msg: data?.message };
    }
  },

  axiosExc: async (type, url, params, data) => {
    try {
      const sess = await Auth.currentSession();
      const resp = await axios({
        method: `${type}`,
        url: API_URL + url,
        headers: {
          Authorization: `Bearer ${sess.accessToken.jwtToken}`,
        },
        data,
        params,
      });
      resp.data =
        resp.data.length && resp.data.map
          ? changeCase.toCamelfromSnakeList(resp.data)
          : changeCase.toCamelfromSnakeArr(resp.data);
      return resp.data;
    } catch (error) {
      console.log(url, error);
      const { status, data } = error.response ?? error.message;
      handleError(status, { msg: data?.message });
      return { error, status, data, msg: data?.message };
    }
  },

  /*

  Auth

  */
  authorize: async (userId, code) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/zoom/auth/${code}`);
    return res;
  },

  getZoomAuthCode: async (userId) => {
    const res = await AppBackService.axiosExc("GET", `/users/${userId}/zoom/auth-code`);
    return res;
  },

  upsertZoomAuthCode: async (userId, zAuthCode, zoomLinked = false) => {
    console.log("calling addZoomAuthCode with: ", userId, zAuthCode, zoomLinked);
    const res = await AppBackService.axiosExcNoAauth(
      "POST",
      `/users/${userId}/zoom/auth-code`,
      {},
      {
        zoom_auth_code: zAuthCode,
        zoom_linked: zoomLinked,
      }
    );
    return res;
  },

  refreshZoomAuth: async (userId) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/zoom/auth/refresh`);
    return res;
  },

  /*

  Users

  */

  insertUser: async (source) => {
    const userData = await cogData();
    const body = {
      user_id: userData.cognitoUserName,
      first_name: userData.cognitoGivenName,
      last_name: userData.cognitoFamilyName,
      email: userData.cognitoUserEmail,
      phonenum: userData.cognitoUserPhoneNumber,
      source: source || "web",
    };
    await AppBackService.axiosExc("POST", "/users", {}, body);
  },

  updateUserEmail: async (username, email) => {
    // connect this
  },

  getUser: async (userId) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}`);
    return res;
  },

  /*

  Docs

  */

  // List Documents
  getUserDocs: async (userId, queryParams) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}/docs`, queryParams);

    if (res.docs) {
      res.docs = changeCase.toCamelfromSnakeList(res.docs);
      res.docs.forEach((doc) => {
        if (doc.taps?.length) {
          doc.taps = changeCase.toCamelfromSnakeList(doc.taps);
        }
      });
    }

    return res;
  },

  getUserDoc: async (userId, docId, queryParams) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}/docs/${docId}`, queryParams);

    if (res.docs) {
      res.docs = changeCase.toCamelfromSnakeList(res.docs);
      res.docs.forEach((doc) => {
        if (doc.taps?.length) {
          doc.taps = changeCase.toCamelfromSnakeList(doc.taps);
        }
      });
    }

    return res;
  },

  // Get a Document
  getDoc: async (docId, queryParams) => {
    const res = await AppBackService.axiosExc("Get", `/docs/${docId}`, queryParams);

    if (res.taps) {
      res.taps = changeCase.toCamelfromSnakeList(res.taps);
    }

    return res;
  },

  // Upload a Document
  uploadDoc: async (userId, data) => {
    const res = await AppBackService.axiosExc("POST", `/users/${userId}/docs`, {}, data);
    return res;
  },

  // Update a Document
  updateUserDoc: async (userId, docId, data) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/docs/${docId}`, {}, data);
    return res;
  },

  // Delete a Document
  deleteUserDoc: async (userId, docId) => {
    const res = await AppBackService.axiosExc("DELETE", `/users/${userId}/docs/${docId}`);
    return res;
  },

  /*

  Resources

  */

  getLayouts: async () => {
    const res = await AppBackService.axiosExc("GET", `/layouts`);

    if (res.layouts) {
      res.layouts.forEach((layout) => {
        if (layout?.backgrounds.length) {
          layout.backgrounds = changeCase.toCamelfromSnakeList(layout.backgrounds);
        }
      });
    }

    return res;
  },

  // get all background images associated with a layout
  getBackgroundsByLayout: async (layoutID) => {
    const res = await AppBackService.axiosExc("GET", `/layouts/${layoutID}/backgrounds`);
    return changeCase.toCamelfromSnakeList(res);
  },

  /*

  Taps

  */

  getUserTaps: async (userId, queryParams) => {
    //TODO Once we have the paging working, we will want to generalize into a
    // default params object that can be used for all the paging calls
    const defaultParams = {
      perPage: 10,
      page: 1,
      sort: "date",
      order: "desc",
    };

    const res = await AppBackService.axiosExc("Get", `/users/${userId}/taps`, queryParams ?? defaultParams);

    if (res.taps) {
      res.taps = changeCase.toCamelfromSnakeList(res.taps);
    }

    return res;
  },

  getUserTap: async (userId, tapId, queryParams) => {
    //TODO Once we have the paging working, we will want to generalize into a
    // default params object that can be used for all the paging calls
    const defaultParams = {
      perPage: 10,
      page: 1,
      sort: "date",
      order: "desc",
    };

    const res = await AppBackService.axiosExc("Get", `/users/${userId}/taps/${tapId}`, queryParams ?? defaultParams);

    if (res.taps) {
      res.taps = changeCase.toCamelfromSnakeArr(res.taps);
    }

    return res;
  },

  updateUserTap: async (userId, tapId, data) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/taps/${tapId}`, {}, data);
    return res;
  },

  deleteUserTap: async (userId, tapId) => {
    const res = await AppBackService.axiosExc("DELETE", `/users/${userId}/taps/${tapId}`);
    return res;
  },

  createUserTap: async (userId, data) => {
    const res = await AppBackService.axiosExc("POST", `/users/${userId}/taps`, {}, data);
    return res;
  },

  getTapestriesDummyData: async (userName, page) => {
    // const res = await axiosExc("GET", `/users/${userName}/tapestries?page=${page}`, {});
    // returning dummy data pending API completion

    // const res = page < 3 ? tapestriesDummyData() : [];
    const res = [];
    return res;
  },

  updateTapestryDummy: (userName, documentID, update) => {},

  /*

  Meetings

  */

  syncMeetings: async (userId) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/meetings/sync`);
    return res;
  },

  getMeetingsDummyData: async () => {
    // const res = await axiosExc("GET", `/users/${userName}/tapestries?page=${page}`, {});
    // returning dummy data pending API completion
    return meetingsDummyData();
  },

  getUserMeetings: async (userId) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}/meetings`);

    if (res.meetings) {
      res.meetings = changeCase.toCamelfromSnakeList(res.meetings);
    }

    return res;
  },

  getUserMeeting: async (userId, meetingId) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}/meetings/${meetingId}`);
    return res;
  },

  updateMeeting: async (userId, meetingId, data) => {
    const res = await AppBackService.axiosExc("PUT", `/users/${userId}/meetings/${meetingId}`, {}, data);
    return res;
  },

  getUserTimeline: async (userId) => {
    const res = await AppBackService.axiosExc("GET", `/users/${userId}/timeline`);

    if (res.meetings) {
      res.meetings = changeCase.toCamelfromSnakeList(res.meetings);
      res.meetings.forEach((meetings) => {
        if (meetings.taps?.length) {
          meetings.taps = changeCase.toCamelfromSnakeList(meetings.taps);
        }
      });
    }

    return res;
  },

  /*

  Notifications

  */

  getUserNotifications: async (userId) => {
    const res = await AppBackService.axiosExc("Get", `/users/${userId}/notifs`);

    if (res.notifs) {
      res.notifs = changeCase.toCamelfromSnakeList(res.notifs);
      res.notifs.forEach((n) => {
        n.event = changeCase.toCamelfromSnakeArr(n.event);
        if (n.tap) {
          n.tap = changeCase.toCamelfromSnakeArr(n.tap);
        }
      });
    }

    return res;
  },
};

function meetingsDummyData(n) {
  const data = [];
  const numData = n || 12;
  for (let i = 0; i < numData; i++) {
    data.push({
      created: "Tue, 07 Feb 2023 02:41:32 GMT",
      doc_id: 1,
      duration: 46,
      external_id: 1,
      host_id: "GSFrfGtzTWyD8MnWIwJ0qR",
      id: uuid(),
      purpose: "this is a purpose 11",
      start_time: "Sat, 17 Jun 2023 00:00:00 GMT",
      timezone: "America/Chicago",
      title: "Vint Marconi Panel - Testing 2",
      type: 2,
      user_id: "be8a5b31-c300-4953-bc28-fd40bb2ed272",
      uuid: "be8a5b31-c300-4953-bc28-fd40bb2ed272",
      recurring: false,
    });
  }
  for (let i = 0; i < 3; i++) {
    data.push({
      created: "Tue, 07 Feb 2023 02:41:32 GMT",
      doc_id: 1,
      duration: 46,
      external_id: 1,
      host_id: "GSFrfGtzTWyD8MnWIwJ0qR",
      id: uuid(),
      purpose: "this is a purpose 11",
      start_time: "Sat, 17 Jun 2023 00:00:00 GMT",
      timezone: "America/Chicago",
      title: "Vint Marconi Panel - Testing 2",
      type: 2,
      user_id: "be8a5b31-c300-4953-bc28-fd40bb2ed272",
      uuid: "be8a5b31-c300-4953-bc28-fd40bb2ed272",
      recurring: true,
    });
  }
  return data;
}

export default AppBackService;
