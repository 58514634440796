import { useState, useEffect, useContext, useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Skeleton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";

const imageStyles = {
  width: "200px",
  marginRight: "10px",
  marginBottom: "10px",
  boxSizing: "border-box",
  cursor: "pointer",
  position: "relative",
  "& img": {
    width: "200px",
    display: "block",
  },
  "&:hover": {
    opacity: 0.7,
  },
};
const styles = {
  thumbnail: {
    marginRight: "20px",
    marginBottom: "10px",
    marginTop: "10px",
    display: "inline-block",
  },
  imageSelect: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  image: imageStyles,
  selectedImage: {
    ...imageStyles,
    "& img": {
      width: "200px",
      display: "block",
      outline: "4px solid #009698",
    },
  },
  heading: {
    fontWeight: "bold",
    color: "#051817",
    marginTop: "10px",
  },
  layoutTitle: {
    position: "absolute",
    textAlign: "center",
    top: "8px",
    fontWeight: "bold",
    padding: "4px 8px",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const defaultBackgrounds = {
  "63101d44ee4bb1ce3947970c": {
    id: "6310696b7e5a0b703c63db73",
    imageUrl: "https://vt-imgs.s3.us-west-2.amazonaws.com/bg_6310696b7e5a0b703c63db73.png",
    description: "Text Color",
  },
  "605edbebcee46a5c13d585c7": {
    id: "6303f7b7c999c802abdcb3c5",
    imageUrl: "https://vt-imgs.s3.us-west-2.amazonaws.com/bg_6303f7b7c999c802abdcb3c5.png",
    description: "Landscape Classic",
  },
  "607fbdc9483dd68dc09e48b5": {
    id: "6303f5c1a614813c650f4b96",
    imageUrl: "https://vt-imgs.s3.us-west-2.amazonaws.com/bg_6303f5c1a614813c650f4b96.png",
    description: "Celestial Andromeda",
  },
  "636c728f9437436cf0f1e7b6": {
    id: "64518b3619e66d67f3fbc301",
    imageUrl: "https://vt-imgs.s3.us-west-2.amazonaws.com/bg_64518b3619e66d67f3fbc301.png",
    description: "Cityscape Colors",
  },
  "634e017524ea413764388073": {
    id: "63a288d2e4effcdb699e7b3e",
    imageUrl: "https://vt-imgs.s3.us-west-2.amazonaws.com/bg_63a288d2e4effcdb699e7b3e.png",
    description: "Skandi People",
  },
};
const activeLayouts = [
  "63101d44ee4bb1ce3947970c", // text
  "605edbebcee46a5c13d585c7", // landscape
  "607fbdc9483dd68dc09e48b5", // celestial
  "636c728f9437436cf0f1e7b6", // cityscape
  "634e017524ea413764388073", // skandi
];
const defaultLayout = "605edbebcee46a5c13d585c7";

const CreateTapModal = ({ open, setOpen, doc, onCreated }) => {
  const [error, setError] = useState(false);
  const [layouts, setLayouts] = useState();
  const [selectedLayout, setSelectedLayout] = useState();
  const [selectedBackground, setSelectedBackground] = useState();
  const active = useRef(true);

  const { userInfo, meetingsSynced, loggingIn, setNotifications } = useContext(RootContext);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  // fetch data
  useEffect(() => {
    async function fetchData() {
      const data = await AppBackService.getLayouts();
      if (!data || data.error || !data.layouts?.length) {
        setError(true);
      } else {
        const layoutsObj = {};
        for (const layout of data.layouts) {
          if (layout.active || activeLayouts.includes(layout.id)) {
            const defaultBackground = layout.backgrounds.find((bg) => bg.default) || defaultBackgrounds[layout.id].id;
            layout.backgrounds.sort((a, b) => (defaultBackground === a.id ? -1 : defaultBackground === b.id ? 1 : 0));
            layoutsObj[layout.id] = layout;
          }
        }
        setLayouts(layoutsObj);
        setSelectedLayout(data.layouts.find((layout) => layout.default)?.id || defaultLayout);
      }
    }
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchData();
    }
  }, [loggingIn, meetingsSynced, userInfo]);

  // assign a default background when a layout is selected
  useEffect(() => {
    if (layouts) {
      setSelectedBackground(
        layouts[selectedLayout].backgrounds.find((bg) => bg.default)?.id || defaultBackgrounds[selectedLayout].id
      );
    }
  }, [layouts, selectedLayout]);

  const selectLayout = useCallback((layoutId) => {
    setSelectedLayout(layoutId);
  }, []);

  const selectBackground = useCallback((backgroundId) => {
    setSelectedBackground(backgroundId);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const tapNotifInterval = useCallback(() => {
    const numIntervals = 6;
    let intervalCount = 0;
    const intervalTime = 10000;
    const interval = setInterval(async () => {
      const data = await AppBackService.getUserNotifications(userInfo.username);
      if (
        data &&
        !data.error &&
        data.notifs?.length &&
        data.notifs[0].type === "tapgen" &&
        data.notifs[0].action === "create" &&
        data.notifs[0].msg.toLowerCase() === "success" &&
        data.notifs[0].tap
      ) {
        setNotifications((notifications) => [...data.notifs, ...notifications]);
        if (active.current) {
          onCreated({ type: "resolve", success: true, tap: data.notifs[0].tap });
        }
        clearInterval(interval);
      }
      intervalCount++;
      if (intervalCount > numIntervals - 1) {
        clearInterval(interval);
      }
    }, intervalTime);
  }, [onCreated, setNotifications, userInfo]);

  const createTap = useCallback(async () => {
    if (doc) {
      const resp = await AppBackService.createUserTap(userInfo?.username, {
        doc_id: doc.docId,
        layout_id: selectedLayout,
        background_id: selectedBackground,
      });
      onCreated({ type: "submit", success: resp && !resp.error });
      tapNotifInterval();
      setOpen(false);
    }
  }, [doc, onCreated, selectedBackground, selectedLayout, setOpen, tapNotifInterval, userInfo]);

  const imagesSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Skeleton key={i} variant="rectangular" width={190} height={120} sx={styles.thumbnail} />
      )),
    []
  );

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeModal}>
      <DialogTitle>Create a Tapestry</DialogTitle>
      {error ? (
        <DialogContent>
          <ErrorIcon />
          <DialogContentText>Error loading layouts, please try again later.</DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          {doc && <DialogContentText sx={{ ...styles.heading, marginTop: 0 }}>Document: {doc.title}</DialogContentText>}
          <DialogContentText sx={styles.heading}>Select a Layout:</DialogContentText>
          {layouts ? (
            <Box sx={styles.imageSelect}>
              {Object.values(layouts)
                .sort((a, b) => {
                  const layout = Object.values(layouts).find((layout) => layout.default)?.id || defaultLayout;
                  return layout === a.id ? -1 : layout === b.id ? 1 : 0;
                })
                .map((layout) => {
                  const defaultBackground =
                    layout.backgrounds.find((bg) => bg.default) || defaultBackgrounds[layout.id];
                  return (
                    <Box
                      key={layout.id}
                      sx={selectedLayout === layout.id ? styles.selectedImage : styles.image}
                      onClick={() => selectLayout(layout.id)}>
                      <img src={defaultBackground.imageUrl} alt={defaultBackground.description} draggable="false" />
                      <Typography sx={styles.layoutTitle}>{toTitleCase(layout.name)}</Typography>
                    </Box>
                  );
                })}
            </Box>
          ) : (
            imagesSkeleton
          )}
          {selectedLayout && (
            <>
              <DialogContentText sx={styles.heading}>Select a Background:</DialogContentText>
              <Box sx={styles.imageSelect}>
                {layouts[selectedLayout]?.backgrounds.map((bg) => (
                  <Box
                    key={bg.id}
                    sx={selectedBackground === bg.id ? styles.selectedImage : styles.image}
                    onClick={() => selectBackground(bg.id)}>
                    <img src={bg.imageUrl} alt={bg.description} draggable="false" />
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <Button disabled={!selectedLayout || !selectedBackground || error} onClick={createTap}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
CreateTapModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  doc: PropTypes.object,
  onCreated: PropTypes.func,
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default CreateTapModal;
