import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ErrorBoundary } from "react-error-boundary";
import { Auth } from "aws-amplify";
import { RootContext } from "../context/root-provider";
import NavBar from "./navbar";
import Footer from "./footer";
import { FallbackComponents } from "./error-handling";
import AutoLogout, { SESSION_TIME_OUT } from "./auto-logout";
import { useRoutes } from "./routes";
import useMagicKeys from "./magic-keys";
import Identity from "../identity/identity";
import ZoomRedirect from "../pages/ZoomRedirect";
import MobileIdentity from "../identity/mobileIdentity";
import AppBackService from "../services/appback";
import zoomSdk from "@zoom/appssdk";
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

//get global zoomSDK
let zoomSdkAlias = window.zoomSdk;
zoomSdkAlias = zoomSdk;

const styles = {
  appStyle: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    textAlign: "center",
    WebkitBoxDirection: "normal",
    WebkitBoxOrient: "vertical",
    position: "relative",
  },
  bodyStyle: {
    flex: 1,
    minHeight: "100vh",
    fontFamily: "Nunito, sans-serif",
    WebkitFontSmoothing: "antialiased",
    position: "relative",
    overflow: "hidden",
  },
  footerStyle: { position: "absolute", width: "100%", bottom: 0 },
};

const App = () => {
  const [redirect, setRedirect] = useState();
  const [testAccount, setTestAccount] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [zoomAuthorized, setZoomAuthorized] = useState(false);
  const [userTimeout, setUserTimeout] = useState(false);
  const [unsetMagicKeys, setMagicKeys] = useMagicKeys();
  const [width, setWindowWidth] = useState(0);
  const isMobile = useMemo(() => width <= 899, [width]);
  const isXsMobile = useMemo(() => width <= 353, [width]);
  const { accountActiveRoutes, testAccountRoutes } = useRoutes();
  const [runningContext, setRunningContext] = useState(null);

  let zoomAuthRedirectUrl = "";
  if (window.location.href.indexOf("app.tapestryai.com") > -1) {
    zoomAuthRedirectUrl =
      "https://zoom.us/oauth/authorize?response_type=code&client_id=hyWzwVPQNaZSLQwtpNa8g&redirect_uri=https://app.tapestryai.com/signup";
  } else {
    zoomAuthRedirectUrl =
      "https://zoom.us/oauth/authorize?response_type=code&client_id=a44SXym7TjqcTNeQ6b2Pg&redirect_uri=https://dev.tapestryai.com/signup";
  }

  let {
    authStatus,
    setAuthStatus,
    authAttrs,
    setAuthAttrs,
    userInfo,
    setUserInfo,
    authCode,
    setAuthCode,
    setInZoomClient,
    setMeetingsSynced,
  } = useContext(RootContext);

  const handleLogout = useCallback(async () => {
    try {
      await Auth.signOut();
      setAuthCode(null);
      setAuthStatus(false);
      setAuthAttrs(null);
      setUserInfo(null);
      setRedirect(null);
      setMeetingsSynced(false);
      setZoomAuthorized(false);
      localStorage.removeItem("activeTime");
      unsetMagicKeys();
    } catch (e) {
      console.log("Error in logout: ", e);
    }
  }, [setAuthAttrs, setAuthStatus, setMeetingsSynced, setUserInfo, unsetMagicKeys]);

  useEffect(() => {
    const activeTime = localStorage.getItem("activeTime");
    if (activeTime !== null && dayjs().diff(dayjs(new Date(+activeTime)), "m") > SESSION_TIME_OUT) {
      setUserTimeout(true);
    }
    setUserTimeout(false);
  }, []);

  const updateDimensions = useCallback(() => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }, []);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    if (authStatus && userTimeout) {
      handleLogout();
    }
  }, [authStatus, handleLogout, userTimeout]);

  useEffect(() => {
    async function zAuth() {
      if (authCode) {
        console.log("authCode: ", authCode);
        if (
          (authCode == "zoom_init" && window.location.href.indexOf("?code") == -1) ||
          window.location.href.indexOf("?code=zoom_init") > -1
        ) {
          window.location.replace(zoomAuthRedirectUrl);
        } else {
          //TODO check response for success or failure
          await AppBackService.authorize(authAttrs.sub, authCode);
          setZoomAuthorized(true);
          await AppBackService.upsertZoomAuthCode(authAttrs.sub, authCode, true);
        }
        await AppBackService.syncMeetings(authAttrs.sub);
        setMeetingsSynced(true);
      }
      await AppBackService.refreshZoomAuth(authAttrs.sub);
    }
    if (!zoomAuthorized && authAttrs) {
      zAuth();
    }
  }, [authCode, authAttrs, zoomAuthorized, setMeetingsSynced]);

  useEffect(() => {
    async function linkZoom() {
      const ZoomAuth = await AppBackService.getZoomAuthCode(authAttrs.sub);
      if (ZoomAuth != null && !ZoomAuth.auth?.linked) {
        setAuthCode(ZoomAuth.auth?.code);
      }
    }
    if (authAttrs) {
      linkZoom();
    }
  }, [authAttrs]);

  useEffect(() => {
    if (testAccount) {
      setMagicKeys({ accountId, username: userInfo?.username }, testAccount);
    }
  }, [testAccount, accountId, setMagicKeys, userInfo?.username]);

  useEffect(() => {
    async function configureSdk() {
      try {
        console.log("Initiate SDK configuration.");

        // Configure the JS SDK, required to call JS APIs in the Zoom App
        const configResponse = await zoomSdkAlias.config({
          capabilities: [
            "openUrl",
            "getSupportedJsApis",
            "getRunningContext",
            "cloudRecording",
            "shareApp",
            "getMeetingContext",
            "getRecordingContext",
            "getMeetingUUID",
            "expandApp",
            "launchAppInMeeting",
          ],
        });
        console.log("SDK configured", configResponse);

        // The config method returns the running context of the Zoom App
        //window.zoomSdk.setRunningContext(configResponse.runningContext);

        console.log("App Configured: setRunningContext:", configResponse.runningContext);
        setRunningContext(configResponse.runningContext);

        zoomSdkAlias.onSendAppInvitation((data) => {
          console.log(data);
        });
        zoomSdkAlias.onShareApp((data) => {
          console.log(data);
        });
        setInZoomClient(true);
      } catch (error) {
        console.log(error);
        setInZoomClient(false);
      }
    }
    configureSdk();
  }, [setInZoomClient]);

  const res = useMemo(() => {
    return (
      <div className="App" id="main-content" style={styles.appStyle}>
        <div className="body-content" style={styles.bodyStyle}>
          {authStatus === null && <CircularProgress size={60} sx={{ marginTop: "40px" }} />}
          {authStatus === true && authAttrs && (
            <>
              <NavBar authAttrs={authAttrs} logout={handleLogout} mobileState={isMobile} xsWindow={isXsMobile} />
            </>
          )}

          <ErrorBoundary FallbackComponent={FallbackComponents}>
            {authStatus === false && (
              <Routes>
                <Route exact path="/zoom" element={<ZoomRedirect />} />
                <Route path="*" element={isMobile ? <MobileIdentity /> : <Identity />} />
              </Routes>
            )}
            {authStatus === true && (
              <>
                <AutoLogout logout={handleLogout} />
                {!testAccount && <>{authStatus && accountActiveRoutes(redirect)}</>}
                {testAccount && testAccountRoutes(redirect)}
              </>
            )}
          </ErrorBoundary>
        </div>
        <div style={styles.footerStyle}>
          <Footer />
        </div>
      </div>
    );
  }, [
    authStatus,
    isMobile,
    authAttrs,
    testAccount,
    isXsMobile,
    redirect,
    handleLogout,
    accountActiveRoutes,
    testAccountRoutes,
  ]);
  return res;
};
export default App;
