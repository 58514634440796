import { useMemo } from "react";
import { Toolbar, Box } from "@mui/material";

export default function Footer() {
  const res = useMemo(
    () => (
      <>
        <Toolbar
          variant="footer"
          style={{
            justifyContent: "center",
            marginTop: "0px",
          }}>
          <Box sx={{ fontSize: "0.9rem", textAlign: "center" }} className="notice">
            © {new Date().getFullYear()} Tapestry AI. All rights reserved
          </Box>
        </Toolbar>
      </>
    ),
    []
  );

  return res;
}
