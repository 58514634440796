import React, { useState, useCallback, useMemo } from "react";
import AlertNotification from "../components/AlertNotification";
import { Auth } from "aws-amplify";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import PasswordComponent, { passwordValidation } from "../components/PasswordComponent";

const styles = {
  passwordBoxStyle: { width: "70%", marginBottom: "10px" },
  submitButtonStyle: {
    backgroundColor: "rgba(0, 150, 152, 1)",
    width: "70%",
    "&:hover": { backgroundColor: "rgb(0 96 98)" },
  },
  headerStyle: { marginRight: "10%" },
};

const ChangePassword = ({ userData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertOpen = useCallback(() => setAlertOpen(true), []);

  const handlePasswordChange = useCallback(
    async (model, { setErrors, resetForm, setStatus }) => {
      try {
        await Auth.changePassword(userData, model.old_password, model.new_password);
        const status = { message: "successfully changed password" };
        handleAlertOpen();
        setStatus(status);
        resetForm({ status });
      } catch (error) {
        setErrors({ api: error?.message });
        setStatus({});
      }
    },
    [handleAlertOpen, userData]
  );

  const res = useMemo(
    () => (
      <>
        <h3 style={styles.headerStyle}>Change Password</h3>
        <>
          <Formik
            initialValues={{ old_password: "", new_password: "" }}
            validationSchema={yup.object().shape({
              old_password: yup.string("Enter your current password").required("Current password is required"),
              new_password: passwordValidation().notOneOf(
                [yup.ref("old_password")],
                "New password same as currect password."
              ),
            })}
            onSubmit={(values, formikBag) => {
              handlePasswordChange(values, formikBag);
            }}>
            {({ values, errors, touched, status, handleChange }) => (
              <Form>
                {errors?.api && <p className="error">{errors?.api}</p>}
                {status?.message && <p className="success">{status?.message}</p>}
                <TextField
                  fullWidth
                  variant="outlined"
                  id="oldpassword"
                  name="old_password"
                  label="Current Password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  value={values.old_password}
                  onChange={handleChange}
                  error={touched.old_password && Boolean(errors.old_password)}
                  helperText={touched.old_password && errors.old_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={(event) => event.preventDefault()}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={styles.passwordBoxStyle}
                />
                <PasswordComponent
                  values={values}
                  label="New Password"
                  name="new_password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={values.new_password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={touched.new_password && Boolean(touched.new_password)}
                  helperText={touched.new_password && errors.new_password}
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  onMouseDown={(event) => event.preventDefault()}
                  style={styles.passwordBoxStyle}
                />
                <Button
                  type="submit"
                  variant="contained"
                  id="change_password_submit_button"
                  sx={styles.submitButtonStyle}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </>
        <AlertNotification
          message="New user settings have been saved."
          severity={null}
          open={alertOpen}
          setOpen={setAlertOpen}
        />
      </>
    ),
    [alertOpen, handlePasswordChange, showConfirmPassword, showPassword]
  );

  return res;
};

export default ChangePassword;
