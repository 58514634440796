import { useCallback, useMemo, useState } from "react";
import { Grid, Box, Typography, TextField, Button, Link } from "@mui/material";
import AppBackService from "../services/appback";
import { useFormik } from "formik";
import * as yup from "yup";
import PuzzleFaces from "../assets/puzzle_faces_large.png";

const styles = {
  rightBoxStyle: { marginRight: "12vw" },
  innerBoxStyle: { paddingLeft: "25px", paddingTop: "20px", paddingRight: "25px", paddingBottom: "20px" },
  titleStyle: {
    fontSize: "36px",
    lineHeight: "46px",
    color: "rgba(5, 24, 23, 1)",
    fontWeight: 700,
    marginBottom: "18px",
  },
  subtitleTextStyle: { fontSize: "24px", marginBottom: "20px" },
  textStyle: { fontSize: "16px", marginBottom: "12px" },
  textFieldStyle: {
    width: "72%",
    minWidth: "260px",
    marginBottom: "0px",
    borderColor: "rgba(0, 150, 152, 1)",
    fieldset: { borderColor: "rgba(0, 150, 152, 1)" },
    "& fieldset": { borderColor: "rgba(0, 150, 152, 1)" },
    "& .MuiOutlinedInputNotchedOutline": {
      borderColor: "rgba(0, 150, 152, 1)",
    },
    backgroundColor: "white",
  },
  submitButtonStyle: {
    backgroundColor: "rgba(0, 150, 152, 1)",
    borderRadius: "8px",
    marginTop: "12px",
    marginBottom: "20px",
  },
  linkStyle: { color: "rgba(0, 150, 152, 1)" },
};

const validationSchema = yup.object({
  email_address: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
});

const EarlyAccessPage = () => {
  const [values, setValues] = useState({
    email: "",
    submitted: false,
  });

  const formik = useFormik({
    initialValues: {
      email_address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = useCallback((model) => {
    setValues({ email: model.email_address, submitted: true });
    AppBackService.logEarlyAccess({ email: model.email_address, datetime: Date.now() });
  }, []);
  const onKeyPress = useCallback(
    (e) => {
      if (e.which === 13) {
        e.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik]
  );

  const res = useMemo(
    () => (
      <Grid style={{ marginTop: "10px", marginBottom: "20px" }} container alignItems="center">
        <Grid item xs={12} sm={12} md={6} textAlign={{ sm: "center", md: "right" }}>
          <Box
            component="img"
            src={PuzzleFaces}
            alt="puzzle faces"
            width={{ xs: "75%", sm: "75%", md: "auto" }}
            maxWidth="441px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} textAlign={{ sm: "center", md: "left" }}>
          <Box backgroundColor="#F1F9FB" marginRight={{ md: "12vw" }}>
            <Box sx={styles.innerBoxStyle}>
              <div>
                {!values.submitted ? (
                  <div>
                    <Typography sx={styles.titleStyle}>Meetings work when everyone gets the picture</Typography>
                    <Typography sx={styles.subtitleTextStyle}>Inspire engagement and creativity</Typography>
                    <Typography sx={styles.textStyle}>Add your email to be notified of early access.</Typography>
                    <div>
                      <form onSubmit={formik.handleSubmit}>
                        <TextField
                          sx={styles.textFieldStyle}
                          variant="outlined"
                          id="email_address"
                          name="email_address"
                          placeholder="Email Address"
                          value={formik.values.email_address}
                          onChange={formik.handleChange}
                          error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                          helperText={formik.touched.email_address && formik.errors.email_address}
                          onKeyPress={onKeyPress}
                          size="small"
                        />
                        <br />
                        <Button type="submit" variant="contained" sx={styles.submitButtonStyle} id="submit_button">
                          Request Access
                        </Button>
                      </form>
                    </div>
                  </div>
                ) : (
                  <>
                    <Typography>
                      We’ve added <strong>{values.email}</strong> to our early access list!
                    </Typography>
                    <p />
                    <Typography>We will notify you when the next round of early access is open.</Typography>
                    <p />
                    <Link href="/early" sx={styles.linkStyle}>
                      Add another email
                    </Link>
                  </>
                )}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    ),
    [
      formik.errors.email_address,
      formik.handleChange,
      formik.handleSubmit,
      formik.touched.email_address,
      formik.values.email_address,
      onKeyPress,
      values.submitted,
      values.email,
    ]
  );

  return res;
};

export default EarlyAccessPage;
