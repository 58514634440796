import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from "@mui/material";
import PropTypes from "prop-types";

const ConfirmDialogComponent = React.forwardRef(
  ({ dialogOpen, setDialogOpen, children, confirmToBack, menuName }, ref) => (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-describedby="alert-dialog-description"
        ref={ref}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions id="MuiDialogActions-root">
          <Button
            style={{ backgroundColor: "#1685d3", color: "white" }}
            onClick={() => confirmToBack(menuName)}
            id="close_button">
            Ok
          </Button>
          <Button
            style={{ backgroundColor: "#1685d3", color: "white" }}
            onClick={() => setDialogOpen(false)}
            id="close_button">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
);

ConfirmDialogComponent.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  confirmToBack: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  menuName: PropTypes.string.isRequired,
};
export default ConfirmDialogComponent;
