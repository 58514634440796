import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import CreateTapModal from "../components/CreateTapModal";
import { useSnackbar } from "../components/AlertNotification";
import { useDocumentActions, useTapActions } from "../services/hooks";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import "./DocumentPage.scss";

const styles = {
  container: {
    padding: "32px 64px",
    marginBottom: "64px",
    textAlign: "left",
  },
  header: {
    color: "#051817",
    fontWeight: 700,
    fontSize: "36px",
  },
  actions: {
    display: "flex",
    marginTop: "10px",
  },
  actionButton: {
    color: "#009698",
    marginRight: "10px",
    textTransform: "none",
    fontWeight: 600,
  },
  skeleton: {
    marginBottom: "20px",
  },
  docTitle: {
    marginRight: "12px",
    fontWeight: 700,
    fontSize: "24px",
    color: "#051817",
  },
  docDate: {
    fontSize: "12px",
    color: "#051817",
    alignSelf: "flex-end",
    lineHeight: "27px",
  },
  taps: {
    marginTop: "10px",
    display: "flex",
    boxSizing: "border-box",
    overflowX: "auto",
  },
  tapLink: {
    position: "relative",
    display: "inline-block",
    margin: "16px",
    marginBottom: 0,
  },
  tapDeleteContainer: {
    backgroundColor: "#ffffff",
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    borderRadius: "20px",
    zIndex: 10,
  },
  tapDelete: {
    color: "#666666",
    display: "block",
    "&:hover": {
      color: "#df380f",
    },
  },
  tapImg: {
    height: "140px",
  },
  docContent: {
    marginTop: "20px",
    padding: "32px",
    backgroundColor: "#F1F9FB",
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "pre-line",
  },
};

const DocumentPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [doc, setDoc] = useState([]);
  const [createTapModalOpen, setCreateTapModalOpen] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const navigate = useNavigate();

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { docId } = useParams();
  const { showSnackbar, snackbarEl } = useSnackbar();

  useEffect(() => {
    setSelectedTab("documents");
  }, [setSelectedTab]);

  // document action handlers

  const updateTitle = useCallback(
    (title) => {
      if (!title) {
        showSnackbar("Unable to update title", "error");
      } else {
        setDoc((doc) => ({ ...doc, title }));
        showSnackbar("Document title updated", "success");
      }
    },
    [showSnackbar]
  );

  const resolveDelete = useCallback(
    (docId) => {
      if (!docId) {
        showSnackbar("Unable to delete Document", "error");
      } else {
        setRedirect(true);
      }
    },
    [showSnackbar]
  );

  const { setDeletingDocument, deleteDocConfirm, setEditingTitle, editTitleDialog } = useDocumentActions(
    updateTitle,
    resolveDelete
  );

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setDoc((doc) => {
          const docCopy = { ...doc };
          const tapIndex = docCopy.taps.findIndex((tap) => tap.tapId === tapId);
          if (tapIndex >= 0) {
            docCopy.taps.splice(tapIndex, 1);
          }
          return docCopy;
        });
        showSnackbar("Tapestry deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(
    (tapId, title) => {
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap]
  );

  // initiate document actions

  const editTitle = useCallback(() => {
    setEditingTitle({ title: doc.title, docId });
  }, [doc, docId, setEditingTitle]);

  const deleteDoc = useCallback(() => {
    setDeletingDocument({ title: doc.title, docId });
  }, [doc, docId, setDeletingDocument]);

  const fetchDoc = useCallback(
    async (showLoading = true) => {
      if (userInfo?.username) {
        if (showLoading) {
          setLoading(true);
        }
        const data = await AppBackService.getUserDoc(userInfo.username, docId);
        if (!data || data.error) {
          setError(true);
        } else {
          if (data.docs?.length) {
            data.docs.forEach((doc) => {
              if (doc.created) {
                doc.created = dayjs(isNaN(+doc.created) ? doc.created : +doc.created);
              }
            });
            setDoc(data.docs[0]);
          }
        }
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [docId, userInfo]
  );

  // init, fetch docs
  useEffect(() => {
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchDoc();
    }
  }, [fetchDoc, loggingIn, meetingsSynced, userInfo]);

  const onCreatedTap = useCallback(
    (created) => {
      if (created.type === "submit") {
        if (created.success) {
          showSnackbar("Creating Tapestry...", "info");
        } else {
          showSnackbar("Unable to create Tapestry", "error");
        }
      } else if (created.type === "resolve" && created.success) {
        showSnackbar(`Tapestry "${created.tap?.title}" created`, "success", "View", () => {
          navigate("/tapestry/" + created.tap?.tapId);
        });
        fetchDoc();
      }
    },
    [fetchDoc, navigate, showSnackbar]
  );

  const docSkeleton = useMemo(
    () => (
      <Box sx={{ marginBottom: "20px", width: "100%", maxWidth: "calc(100vw - 128px)", boxSizing: "border-box" }}>
        <Skeleton variant="rectangular" width="100%" height={62} sx={styles.skeleton} />
        <Skeleton variant="rectangular" width="100%" height={100} sx={styles.skeleton} />
        <Skeleton variant="rectangular" width="100%" height={400} sx={styles.skeleton} />
      </Box>
    ),
    []
  );

  const errorEl = useMemo(
    () => (
      <Box className="error">
        <ErrorIcon />
      </Box>
    ),
    []
  );

  if (!docId || redirect) {
    return <Navigate to="/documents" />;
  }
  if (error) {
    return errorEl;
  }
  return (
    <Box sx={styles.container}>
      {loading || !doc ? (
        docSkeleton
      ) : (
        <Box sx={styles.doc}>
          <Box sx={styles.header}>
            <Typography sx={styles.docTitle}>{doc.title}</Typography>
            {doc.created && <Typography sx={styles.docDate}>{doc.created.format("llll")}</Typography>}
            <Box sx={styles.actions}>
              <Button sx={styles.actionButton} size="medium" startIcon={<EditIcon />} onClick={editTitle}>
                Edit title
              </Button>
              <Button
                sx={{
                  ...styles.actionButton,
                  "&:hover": {
                    color: "#df380f",
                  },
                }}
                size="medium"
                startIcon={<CancelIcon />}
                onClick={deleteDoc}>
                Delete
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#009698",
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": {
                    background: "#006062",
                  },
                }}
                size="medium"
                onClick={() => {
                  setCreateTapModalOpen(true);
                }}>
                Create Tapestry
              </Button>
            </Box>
          </Box>
          {doc.taps.length ? (
            <Box sx={styles.taps}>
              {doc.taps.map((tap) => (
                <Box className="tap-link" key={tap.tapId} style={styles.tapLink}>
                  <Link to={"/tapestry/" + tap.tapId}>
                    <img
                      className="tap-img"
                      src={`https://taps.tapestryai.com/${tap.tapId}_tn.png`}
                      style={styles.tapImg}
                      alt="tapestry"
                    />
                  </Link>
                  <Box className="delete-tap" sx={styles.tapDeleteContainer}>
                    <CancelIcon
                      fontSize="medium"
                      sx={styles.tapDelete}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteTap(tap.tapId, tap.title);
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : null}
          <Box sx={styles.docContent}>{doc.content}</Box>
          {deleteDocConfirm}
          {editTitleDialog}
          {deleteTapConfirm}
          {snackbarEl}
          <CreateTapModal
            open={createTapModalOpen}
            setOpen={setCreateTapModalOpen}
            doc={doc}
            onCreated={onCreatedTap}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentPage;
