import { useState, useCallback, useMemo, useContext, useRef } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { AppBar, Menu, MenuItem, Box, Grid, Typography, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import bellIcon from "../assets/bell.svg";
import bellActiveIcon from "../assets/bell-active.svg";
// import SearchIcon from "@mui/icons-material/Search";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CloseIcon from "@mui/icons-material/Close";
import "../components/Header.scss";
import Logo from "../assets/logo_with_text.png";
import LogoNoText from "../assets/logo_no_text.png";
import CalendarIcon from "../assets/calendar_icon.png";
import DocIcon from "../assets/doc_icon.png";
import { RootContext } from "../context/root-provider";
import "./navbar.scss";
import NotificationTray from "../components/NotificationTray";

const styles = {
  appBarStyle: { paddingRight: "12px", paddingTop: "5px" },
  outerBoxStyle: { height: "64px" },
  mobileMenuIconStyle: {
    alignItems: "left",
    textAlign: "left",
    backgroundColor: "transparent",
  },
  desktopMenuBoxStyle: {
    display: "flex",
  },
  logoContainerStyle: { textAlign: "left", paddingLeft: "12px" },
  closeButtonContainerStyle: { textAlign: "right" },
  closeButtonStyle: { marginTop: "13px", marginRight: "35px", fontSize: 28 },
  mobileLogoContainerStyle: { textAlign: "center" },
  logoStyle: { marginTop: "3px", cursor: "pointer" },
  centerGridStyle: {
    marginTop: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightGridContainerStyle: { marginTop: "0px", marginLeft: "-40px" },
  menuItemStyle: {
    fontWeight: 400,
    fontSize: 17,
    margin: "0 6px",
  },
  iconStyle: { paddingRight: "6px" },
  nameButtonTextStyle: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 17,
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      color: "common.black",
    },
    paddingRight: "8px",
    paddingTop: "9px",
  },
  dropDownMenuStyle: {
    marginTop: "3px",
  },
  circleContainerStyle: { textAlign: "left", marginLeft: -10, marginTop: -10 },
  circleStyle: { fontSize: 43, position: "absolute", "&:hover": { color: "rgba(0, 150, 152, 1)" } },
  selectedCircleStyle: { fontSize: 43, position: "absolute", color: "rgba(0, 150, 152, 1)" },
  menuButtonStyle: { cursor: "pointer" },
  bellIconStyle: { height: "26px", marginRight: "2px" },
  righthandIconsStyle: { cursor: "pointer", fontSize: 28, "&:hover": { color: "rgba(0, 150, 152, 1)" } },
  selectedNotificationIconStyle: { cursor: "pointer", fontSize: 28, color: "rgba(0, 150, 152, 1)" },
  notificationButtonContainerStyle: { cursor: "pointer" },
  mobileMenuBoxStyle: { backgroundColor: "#455C70", color: "white", paddingTop: "5px" },
  mobileMenuDrawerStyle: { backgroundColor: "transparent", overflow: "hidden" },
  mobileMenuContainerStyle: { paddingBottom: "15px" },
  nameContainerStyle: { marginLeft: "18px", marginTop: "12px", fontWeight: "bold", fontSize: 17 },
  profileSettingsContainerStyle: { paddingLeft: "47px", paddingTop: "7px", paddingBottom: "10px" },
  profileSettingsTypographyStyle: { paddingTop: "10px", cursor: "pointer", "&:hover": { textDecoration: "underline" } },
};

const NavBar = ({ authAttrs, logout, mobileState, xsWindow }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const notificationButtonRef = useRef();

  const { selectedTab, notifications } = useContext(RootContext);

  const navigate = useNavigate();

  const handleLogOut = useCallback(
    async (event) => {
      await event.preventDefault();
      setMenuAnchorEl(null);
      setNotificationsAnchorEl(null);
      logout();
    },
    [logout]
  );

  const handleMenuClick = useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleNotificationsClick = useCallback((event) => {
    setNotificationsAnchorEl(event.currentTarget);
  }, []);

  const handleNotificationsClose = useCallback(() => {
    setNotificationsAnchorEl(null);
  }, []);

  const handleNavigation = useCallback(
    (url) => {
      navigate(url);
      setMenuAnchorEl(null);
      setMobileMenuIsOpen(false);
    },
    [navigate]
  );

  const externalLink = useCallback((url) => {
    openLink(url);
    setMenuAnchorEl(null);
    setMobileMenuIsOpen(false);
  }, []);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuIsOpen((m) => !m);
  }, []);

  const meetingsMenu = useMemo(
    () => (
      <MenuItem
        className={classNames("nav-item", { selected: selectedTab === "meetings" })}
        onClick={() => handleNavigation("/meetings")}
        sx={styles.menuItemStyle}>
        <img src={CalendarIcon} width="24px" height="24px" style={styles.iconStyle} alt="calendar icon" />
        <Typography>Meetings</Typography>
      </MenuItem>
    ),
    [handleNavigation, selectedTab]
  );

  const documentsMenu = useMemo(
    () => (
      <MenuItem
        className={classNames("nav-item", { selected: selectedTab === "documents" })}
        onClick={() => handleNavigation("/documents")}
        sx={styles.menuItemStyle}>
        <img src={DocIcon} width="24px" height="24px" style={styles.iconStyle} alt="document icon" />
        <Typography>Documents</Typography>
      </MenuItem>
    ),
    [handleNavigation, selectedTab]
  );

  const initialsIcon = useMemo(
    () => (
      <>
        <div style={styles.circleContainerStyle}>
          {!!menuAnchorEl ? (
            <PanoramaFishEyeIcon sx={styles.selectedCircleStyle} />
          ) : (
            <PanoramaFishEyeIcon sx={styles.circleStyle} />
          )}
        </div>
        <div style={styles.nameButtonTextStyle}>
          {Array.from(authAttrs.given_name)[0]}
          {Array.from(authAttrs.family_name)[0]}
        </div>
      </>
    ),
    [authAttrs?.family_name, authAttrs?.given_name, menuAnchorEl]
  );

  const notificationButton = useMemo(
    () => (
      <>
        <Box
          sx={{ marginRight: "5px", marginTop: "-2px" }}
          ref={notificationButtonRef}
          onClick={handleNotificationsClick}>
          <img
            src={notifications.length ? bellActiveIcon : bellIcon}
            style={styles.bellIconStyle}
            alt="Notifications"
          />
        </Box>
        <Menu
          id="notifications-menu"
          anchorEl={notificationsAnchorEl}
          keepMounted
          open={!!notificationsAnchorEl}
          onClose={handleNotificationsClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={styles.dropDownMenuStyle}>
          <NotificationTray close={handleNotificationsClose} />
        </Menu>
      </>
    ),
    [handleNotificationsClick, notifications, notificationsAnchorEl, handleNotificationsClose]
  );

  const menuButton = useMemo(
    () => (
      <>
        <div onClick={handleMenuClick} id="menu_button" style={styles.menuButtonStyle}>
          {initialsIcon}
        </div>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={handleMenuClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={styles.dropDownMenuStyle}>
          <MenuItem onClick={() => handleNavigation("/settings")}>Settings</MenuItem>
          <MenuItem onClick={() => externalLink("https://support.vtapestry.com/")}>Help</MenuItem>
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </>
    ),
    [handleMenuClick, initialsIcon, menuAnchorEl, handleMenuClose, handleLogOut, handleNavigation, externalLink]
  );

  const logoGridItem = useMemo(
    () => (
      <Grid item xs={6} sm={6} md={4} lg={4}>
        {xsWindow ? (
          <div style={styles.logoContainerStyle}>
            <img
              src={LogoNoText}
              style={styles.logoStyle}
              height="42px"
              width="42px"
              onClick={handleNavigation("/")}
              alt="tapestry ai logo"
            />
          </div>
        ) : (
          <div style={styles.logoContainerStyle}>
            <img
              src={Logo}
              style={styles.logoStyle}
              onClick={() => handleNavigation("/")}
              alt="tapestry ai logo with text"
            />
          </div>
        )}
      </Grid>
    ),
    [handleNavigation, xsWindow]
  );

  const mobileMenu = useMemo(
    () => (
      <Box role="presentation" sx={styles.mobileMenuBoxStyle}>
        <Grid container justifyContent="flex-end">
          {logoGridItem}
          <Grid item xs={6} sm={6}>
            <div style={styles.closeButtonContainerStyle}>
              <CloseIcon onClick={toggleMobileMenu} sx={styles.closeButtonStyle} />
            </div>
          </Grid>
        </Grid>
        <p />
        <div style={styles.mobileMenuContainerStyle}>{meetingsMenu}</div>
        <div style={styles.mobileMenuContainerStyle}>{documentsMenu}</div>
        <Typography onClick={() => handleNavigation("/settings")} sx={styles.nameContainerStyle}>
          {authAttrs?.given_name} {authAttrs?.family_name}
        </Typography>
        <div style={styles.profileSettingsContainerStyle}>
          <Typography sx={styles.profileSettingsTypographyStyle} onClick={() => handleNavigation("/settings")}>
            Settings
          </Typography>
          <Typography
            sx={styles.profileSettingsTypographyStyle}
            onClick={() => externalLink("https://support.vtapestry.com/")}>
            Help
          </Typography>
          <Typography sx={styles.profileSettingsTypographyStyle} onClick={handleLogOut}>
            Logout
          </Typography>
        </div>
      </Box>
    ),
    [
      logoGridItem,
      toggleMobileMenu,
      meetingsMenu,
      documentsMenu,
      authAttrs,
      handleLogOut,
      handleNavigation,
      externalLink,
    ]
  );

  const mobileMenuButton = useMemo(
    () => (
      <Box sx={styles.mobileMenuIconStyle}>
        <MenuIcon
          id="fade-button"
          aria-controls={mobileMenuIsOpen ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={mobileMenuIsOpen ? "true" : undefined}
          onClick={toggleMobileMenu}
        />
        <Drawer
          anchor="top"
          open={mobileMenuIsOpen}
          onClose={toggleMobileMenu}
          PaperProps={{ sx: styles.mobileMenuDrawerStyle }}
          variant="persistent">
          {mobileMenu}
        </Drawer>
      </Box>
    ),
    [mobileMenu, mobileMenuIsOpen, toggleMobileMenu]
  );

  const centerGridItem = useMemo(
    () => (
      <>
        {mobileState ? (
          <></>
        ) : (
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Box sx={styles.centerGridStyle}>
              {meetingsMenu}
              {documentsMenu}
            </Box>
          </Grid>
        )}
      </>
    ),
    [mobileState, meetingsMenu, documentsMenu]
  );

  const righthandGridItem = useMemo(
    () => (
      <>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Grid container justifyContent="flex-end" spacing={2} sx={styles.rightGridContainerStyle}>
            <Grid item sx={styles.notificationButtonContainerStyle}>
              {notificationButton}
            </Grid>
            {/* <Grid item>
              <SearchIcon sx={styles.righthandIconsStyle} />
            </Grid> */}
            <Grid item>{mobileState ? <>{mobileMenuButton}</> : <>{menuButton}</>}</Grid>
          </Grid>
        </Grid>
      </>
    ),
    [notificationButton, mobileState, mobileMenuButton, menuButton]
  );

  const res = useMemo(
    () => (
      <Box sx={styles.outerBoxStyle}>
        <AppBar elevation={0} sx={styles.appBarStyle}>
          <Grid container>
            {logoGridItem}
            {centerGridItem}
            {righthandGridItem}
          </Grid>
        </AppBar>
      </Box>
    ),
    [logoGridItem, centerGridItem, righthandGridItem]
  );

  return res;
};

function openUrlFromZoomClient(url) {
  let zoomSdkAlias = window.zoomSdk;

  zoomSdkAlias
    .openUrl({
      url: url,
    })
    .then((result) => {
      console.log("openUrlFromZoomClient called for:", url);
      console.log("openUrlFromZoomClient:result:", result);
    });
}

function openLink(url) {
  if (window.zoomSdk) {
    openUrlFromZoomClient(url);
  } else {
    window.open(url, "_blank");
  }
}

export default NavBar;
