export const changeCase = {
  toCamelfromSnake: (key) => key.replace(/_(\w)/g, (m, p1) => p1.toUpperCase()),
  toCamelfromSnakeArr: (obj) =>
    Object.keys(obj).reduce((acc, key) => {
      acc[changeCase.toCamelfromSnake(key)] = obj[key];
      return acc;
    }, {}),
  toCamelfromSnakeList: (list) =>
    list.map((obj) => {
      return changeCase.toCamelfromSnakeArr(obj);
    }),
};
