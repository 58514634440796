import { Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../assets/logo_with_text_big.png";
import tapDrawing from "../assets/tap_drawing.png";

const styles = {
  background: {
    width: "100vw",
    background: "linear-gradient(71.8deg, #455C70 20.5%, #00A7BC 101.93%)",
    textAlign: "center",
    paddingTop: "62px",
    boxSizing: "border-box",
    fontFamily: "Nunito",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    paddingBottom: "42px",
    boxSizing: "border-box",
  },
  header: {
    fontWeight: "700",
    fontSize: "36px",
    color: "#ffffff",
    marginTop: "48px",
    marginBottom: "16px",
  },
  drawingStyles: {
    width: "350px",
    marginTop: "16px",
    marginBottom: "32px",
  },
  subheading: {
    fontWeight: "700",
    fontSize: "24px",
    color: "#ffffff",
    marginTop: "16px",
    marginBottom: "32px",
  },
  button: {
    backgroundColor: "#F1F9FB",
    color: "#009698",
    fontWeight: "700",
    fontSize: "24px",
    padding: "8px 29px",
    marginBottom: "48px",
    textTransform: "none",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#009698",
      color: "#ffffff",
    },
  },
  footer: {
    display: "inline-block",
    margin: "0 auto",
  },
  footerText: {
    fontSize: "12px",
    color: "#ffffff",
    marginBottom: "16px",
    display: "block",
  },
};

const ZoomRedirect = ({ loggedIn }) => {
  // Redirect to signup page for now
  const redirectUrl = "/signup?code=zoom_init";
  window.location.replace(redirectUrl);

  return (
    <Box sx={{ ...styles.background, minHeight: loggedIn ? "calc(100vh - 80px)" : "100vh" }}>
      <Box sx={styles.container}>
        <Box>
          <Link to="/">
            <img style={{ width: "240px" }} src={logo} alt="TapestryAI logo" />
          </Link>
          <Typography sx={styles.header}>Bring meetings to life with a visual story</Typography>
          <img style={styles.drawingStyles} src={tapDrawing} alt="A drawing of two faces intertwined with symbols" />
          <Typography sx={styles.subheading}>Inspire engagement and innovation</Typography>
          <a
            href="https://zoom.us/oauth/authorize?response_type=code&client_id=hyWzwVPQNaZSLQwtpNa8g&redirect_uri=https://app.tapestryai.com/signup"
            target="_blank"
            rel="noopener noreferrer">
            <Button sx={styles.button} size="large">
              Add to Zoom
            </Button>
          </a>
        </Box>
        <Box sx={styles.footer}>
          <a style={styles.footerText} href="https://support.vtapestry.com">
            Support
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default ZoomRedirect;
