import { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { Box } from "@mui/material";
import { createText, createTextpath } from "./tapestryUtilities";
import "./TapestryView.scss";

const TapestryView = ({ svg, updatedPhrase, width }) => {
  const tapestryContainer = useRef();
  const widthRef = useRef(width);

  const setTapestryDimensions = useCallback(() => {
    const svgEl = tapestryContainer.current?.querySelector("svg.svg-container");
    if (svgEl) {
      const windowHeight = window.innerHeight - 84;
      const windowWidth = (window.innerWidth - (widthRef.current === 1 ? 55 : 65)) * widthRef.current;
      let svgWidth = windowWidth;
      let svgHeight = (svgEl.viewBox.baseVal.height * windowWidth) / svgEl.viewBox.baseVal.width;
      if (svgHeight > windowHeight) {
        svgHeight = windowHeight;
        svgWidth = (svgEl.viewBox.baseVal.width * windowHeight) / svgEl.viewBox.baseVal.height;
      }
      svgEl.style.width = svgWidth;
      svgEl.style.height = svgHeight;
      window.dispatchEvent(
        new CustomEvent("tapestryDimensions", {
          detail: { width: svgWidth, height: svgHeight },
        })
      );
    }
  }, []);

  useEffect(() => {
    widthRef.current = width;
    setTapestryDimensions();
  }, [setTapestryDimensions, width]);

  useEffect(() => {
    window.addEventListener("resize", setTapestryDimensions);
    return () => {
      window.removeEventListener("resize", setTapestryDimensions);
    };
  }, [setTapestryDimensions]);

  // initialize SVG
  useEffect(() => {
    if (svg && tapestryContainer.current) {
      tapestryContainer.current.innerHTML = svg;

      // setup interactivity

      // phrase editing
      tapestryContainer.current?.querySelectorAll("[id^=phrase- i]").forEach((editable) => {
        const eventID = editable.id.slice(7);
        if (editable.tagName === "g") {
          editable.querySelectorAll("text").forEach((text) => {
            text.addEventListener("click", () =>
              window.dispatchEvent(
                new CustomEvent("showPhraseOptions", {
                  detail: { eventID },
                })
              )
            );
          });
        } else if (editable.tagName === "text") {
          editable.addEventListener("click", () =>
            window.dispatchEvent(
              new CustomEvent("showPhraseOptions", {
                detail: { eventID },
              })
            )
          );
        }
      });

      // phrase searching
      tapestryContainer.current
        ?.querySelectorAll(".slot-phrase-container, .symbol-phrase-container, text.viz-text tspan")
        .forEach((searchable) => {
          searchable.addEventListener("click", () =>
            window.dispatchEvent(
              new CustomEvent("selectText", {
                detail: { text: searchable.getAttribute("fulltext") },
              })
            )
          );
        });

      setTapestryDimensions();
    }
  }, [setTapestryDimensions, svg]);

  // update phrases on phrase selection
  useEffect(() => {
    const phraseContainer = tapestryContainer.current?.querySelector("#phrase-" + updatedPhrase?.eventID);
    if (phraseContainer) {
      if (updatedPhrase.selectedPhrase.bodyOptions) {
        // update text body
        const newText = createText({ ...updatedPhrase.selectedPhrase });
        const selectedPhraseContainer = d3.select(phraseContainer);
        selectedPhraseContainer.selectAll("tspan").remove();
        selectedPhraseContainer
          .selectAll("tspan")
          .data(newText.texts)
          .enter()
          .append("tspan")
          .text((d) => d.text)
          .attr("x", 0)
          .attr("dx", (d) => d.renderStyle.width / 2)
          .attr("dy", (d, i) => (i ? d.height : 0))
          .attr("fill", (d) => d.color)
          .on("click", () =>
            window.dispatchEvent(
              new CustomEvent("selectText", {
                detail: { text: updatedPhrase.selectedPhrase.textContent },
              })
            )
          );
      } else {
        // update textpath
        const parent = phraseContainer.parentNode;
        phraseContainer.remove();
        createTextpath({ ...updatedPhrase.selectedPhrase, container: parent });
      }
    }
  }, [updatedPhrase]);

  return <Box sx={{ width }} className="tapestry-container" ref={tapestryContainer}></Box>;
};
TapestryView.propTypes = {
  svg: PropTypes.string,
  updatedPhrase: PropTypes.object,
  width: PropTypes.number,
};

export default TapestryView;
