import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Link } from "react-router-dom";
import { useTapActions } from "../services/hooks";
import CancelIcon from "@mui/icons-material/Cancel";
import symbolLoader from "../assets/symbol_circular_progress.png";
import ShareModal from "./ShareModal";
import "./TapestryCard.scss";

const CARD_WIDTH = "330px";

const cardTopStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "4px 4px 0 0",
};
const styles = {
  tapDeleteContainer: {
    backgroundColor: "#ffffff",
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    borderRadius: "20px",
    zIndex: 10,
  },
  tapDelete: {
    color: "#666666",
    display: "block",
    "&:hover": {
      color: "#df380f",
    },
  },
};

/**
 * Represents a tapestry card.
 *
 * @constructor
 * @param {string} tapestryID -  ID number of the card
 * @param {string} title - Title of the card
 * @param {string} date - Date of tapestry creation
 * @param {string} thumbnail - Tapestry thumbnail image
 * @param {string} state - One of "generated" or "generating"
 */

const TapestryCard = ({ tapId, title, date, thumbnail, state, resolveDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const tapestryLink = useMemo(() => "/tapestry/" + tapId, [tapId]);
  const imageURI = useMemo(() => (tapId ? `https://taps.tapestryai.com/${tapId}.png` : ""), [tapId]);

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveDelete);

  const deleteTap = useCallback(
    (e) => {
      e.stopPropagation();
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap, tapId, title]
  );

  const res = useMemo(
    () => (
      <Grid
        item
        sx={{
          width: CARD_WIDTH,
          borderRadius: "4px",
        }}>
        <Box
          className="tap-container"
          sx={{
            width: "100%",
            background: "#F1F9FB",
            boxShadow: "0px 2px 4px 1px rgba(5, 24, 23, 0.24)",
            borderRadius: "4px",
            padding: "0",
            boxSizing: "border-box",
            position: "relative",
          }}>
          <Box sx={{ width: "100%", height: "192px", borderRadius: "4px 4px 0 0" }}>
            {state === "error" ? (
              <Box sx={{ backgroundColor: "#cccccc", ...cardTopStyles }}>
                <ReportProblemIcon color="error" fontSize="large" />
              </Box>
            ) : state === "generated" ? (
              <Link to={tapestryLink}>
                <img
                  style={{ width: "100%", borderRadius: "4px 4px 0 0" }}
                  src={imageUrl(thumbnail)}
                  alt={"tapestry image for " + title}
                />
              </Link>
            ) : (
              <Box
                sx={{
                  background: "linear-gradient(180deg, #DAF58D 0%, #89E6E8 100%);",
                  ...cardTopStyles,
                }}>
                <img style={{ width: 99, marginBottom: 14 }} src={symbolLoader} alt="loading" />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#051817",
                  }}
                  variant="h3">
                  Creating Tapestry
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              height: "148px",
              width: "100%",
              boxSizing: "border-box",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
            }}>
            <Link to={tapestryLink} style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  textAlign: "left",
                  color: "#051817",
                  marginBottom: "2px",
                }}
                variant="h3">
                {title}
              </Typography>
            </Link>
            <Link to={tapestryLink} style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#051817",
                }}>
                {date}
              </Typography>
            </Link>
            <Box sx={{ flexGrow: 1, position: "relative" }}>
              <Link to={tapestryLink}>
                <Button
                  size="small"
                  startIcon={<VisibilityIcon />}
                  color="secondary"
                  sx={{
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                  }}>
                  View
                </Button>
              </Link>
              <Button
                size="small"
                startIcon={<IosShareIcon />}
                color="secondary"
                sx={{
                  color: "secondary",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                }}
                onClick={handleModalOpen}>
                Share
              </Button>
              <ShareModal open={modalOpen} handleClose={handleModalClose} tapShareUrl={imageURI} title={title} />
            </Box>
          </Box>
          <Box className="delete-tap" sx={styles.tapDeleteContainer}>
            <CancelIcon fontSize="medium" sx={styles.tapDelete} onClick={deleteTap} />
          </Box>
        </Box>
        {deleteTapConfirm}
      </Grid>
    ),
    [
      state,
      tapestryLink,
      thumbnail,
      title,
      date,
      handleModalOpen,
      modalOpen,
      handleModalClose,
      imageURI,
      deleteTap,
      deleteTapConfirm,
    ]
  );

  return res;
};
TapestryCard.propTypes = {
  tapestryID: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  thumbnail: PropTypes.string,
  error: PropTypes.bool,
  resolveDelete: PropTypes.func,
};

function imageUrl(imageName) {
  return "https://taps.tapestryai.com/" + imageName;
}

export default TapestryCard;
