import { createTheme } from "@mui/material/styles";

const highlightColor = "#9cc038";
const mainColor = "#1685d3";
const blockOrange = "#ff8521";
const navbarBlue = "#455C70";

const Theme = createTheme({
  palette: {
    common: {
      highlight: highlightColor,
      orange: blockOrange,
      green: navbarBlue,
    },
    primary: {
      main: mainColor,
    },
    secondary: {
      main: navbarBlue,
    },
    error: {
      main: "#ed1c24",
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
  components: {
    MuiToolbar: {
      variants: [
        {
          props: { variant: "footer" },
          style: {
            backgroundColor: "#333",
            padding: "0.9em 8.2vw !important",
            color: "silver",
            fontSize: "0.8rem",
            fontFamily: "Nunito, sans-serif",
            textAlign: "left",
            lineHeight: "1.2rem",
            marginTop: "16px",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "email" },
          style: {
            textTransform: "lowercase",
            "&:focus": {
              color: "red",
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: navbarBlue,
          height: "64px",
          minHeight: "64px",
        },
      },
    },
  },
});
export default Theme;
