import { useState, useMemo, useCallback, useRef, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import classNames from "classnames";
import dayjs from "dayjs";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { Box, Typography, CircularProgress, Skeleton } from "@mui/material";
import { useTapActions } from "../services/hooks";
import ErrorIcon from "@mui/icons-material/Error";
import ChatIcon from "@mui/icons-material/Chat";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareModal from "../components/ShareModal";
import { useSnackbar } from "../components/AlertNotification";
import DocumentText from "../components/DocumentText";
import InlineEdit from "../components/InlineEdit";
import TapestryView from "../components/TapestryView";
import pointerIcon from "../assets/pointer-green.svg";
import "./DetailPage.scss";

const NUM_PHRASE_OPTIONS = 8;

const tapestryActionStyle = {
  cursor: "pointer",
  marginLeft: "20px",
  display: "inline-block",
  verticalAlign: "middle",
};
const alignMiddleStyle = {
  display: "inline-block",
  verticalAlign: "middle",
};
const editPanelStyle = {
  width: "100%",
  marginTop: "20px",
};
const editPanelHeaderStyle = {
  fontSize: "24px",
  fontWeight: "700",
  marginBottom: "24px",
  textAlign: "left",
};
const thumbnailStyle = {
  height: "120px",
  marginRight: "20px",
};
const primaryGreen = "#169697";
const breakpoint = 1200;

const DetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editingTapestry, setEditingTapestry] = useState(false);
  const [searchingPhrases, setSearchingPhrases] = useState(false);
  const [selectedText, setSelectedText] = useState(null);
  const [tapestryData, setTapestryData] = useState({});
  const [doc, setDoc] = useState({});
  // const [documentSetup, setDocumentSetup] = useState();
  // const documentSetupRef = useRef();
  const [title, setTitle] = useState();
  const [phraseOptions, setPhraseOptions] = useState();
  const [updatedPhrase, setUpdatedPhrase] = useState();
  const [tapestryHeight, setTapestryHeight] = useState("auto");
  const [redirect, setRedirect] = useState(null);
  const transcriptContainerRef = useRef();

  const { tapId } = useParams();
  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setRedirect(true);
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(() => {
    setDeletingTap({ title, tapId });
  }, [setDeletingTap, tapId, title]);

  // data for editing
  const [activeLayout, setActiveLayout] = useState();
  const activeLayoutRef = useRef(activeLayout);
  const [activeBackground, setActiveBackground] = useState();
  const [backgrounds, setBackgrounds] = useState({});
  const [svg, setSvg] = useState({}); // per layout
  const [textpathOptions, setTextpathOptions] = useState({}); // per layout
  const tapestryContainer = useRef();

  useEffect(() => {
    setSelectedTab("tapestries");
  }, [setSelectedTab]);

  // share modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);
  const imageURI = useMemo(
    () => (tapestryData.imageName ? "https://taps.tapestryai.com/" + tapestryData.imageName : ""),
    [tapestryData]
  );

  const searchingPhrasesRef = useRef(searchingPhrases);

  const textpathOptionsRef = useRef(textpathOptions);
  useEffect(() => {
    textpathOptionsRef.current = textpathOptions;
  }, [textpathOptions]);

  const backgroundsRef = useRef(backgrounds);
  useEffect(() => {
    backgroundsRef.current = backgrounds;
  }, [backgrounds]);

  const showPhraseOptions = useCallback((e) => {
    if (
      textpathOptionsRef.current[activeLayoutRef.current] &&
      textpathOptionsRef.current[activeLayoutRef.current][e.detail.eventID] &&
      e.detail.eventID &&
      !searchingPhrasesRef.current
    ) {
      setPhraseOptions(textpathOptionsRef.current[activeLayoutRef.current][e.detail.eventID]);
    }
  }, []);

  // initialize and fetch data
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await AppBackService.getUserTap(userInfo.username, tapId);
      if (!data || data.error) {
        setError(true);
      } else {
        setTapestryData(data.taps);
        if (data.taps) {
          // console.log(data.taps);
          setActiveLayout(data.taps.layoutId);
          activeLayoutRef.current = data.taps.layoutId;

          // fetch tapdata
          const tapdataResp = await axios({
            method: "GET",
            url: `https://taps.tapestryai.com/${data.taps.tapId}.tapdata.json`,
          });
          if (tapdataResp.data) {
            setTextpathOptions({ [data.taps.layoutId]: tapdataResp.data });
          }

          // fetch SVG
          const svgResp = await axios({
            method: "GET",
            url: `https://taps.tapestryai.com/${data.taps.tapId}.svg`,
          });
          // console.log("SVG", svgResp);
          if (svgResp.data) {
            setSvg((svg) => {
              const svgCopy = { ...svg };
              svgCopy[data.taps.layoutId] = svgResp.data;
              return svgCopy;
            });
          }

          // fetch document
          const doc = await AppBackService.getUserDoc(userInfo.username, data.taps.docId);
          if (doc?.docs) {
            setDoc(doc.docs[0]);
          }

          setActiveBackground(data.taps.backgroundId);

          setTitle(data.taps.title);
        }

        // setDocumentSetup(data.documentSetup);
        // documentSetupRef.current = data.documentSetup;
        window.addEventListener("showPhraseOptions", showPhraseOptions);
      }
      setLoading(false);
    }

    if (userInfo?.username && meetingsSynced && !loggingIn && tapId) {
      fetchData();
    }

    function updateTapestryDimensions(e) {
      setTapestryHeight(e.detail.height || "auto");
    }

    function searchText(e) {
      if (e.detail.text && searchingPhrasesRef.current) {
        const phrases = phrasesData(textpathOptionsRef.current[activeLayoutRef.current]);
        setSelectedText({ text: e.detail.text, indexes: phrases[e.detail.text] });
      }
    }

    window.addEventListener("tapestryDimensions", updateTapestryDimensions);
    window.addEventListener("selectText", searchText);
    return () => {
      window.removeEventListener("showPhraseOptions", showPhraseOptions);
      window.removeEventListener("tapestryDimensions", updateTapestryDimensions);
      window.removeEventListener("selectText", searchText);
    };
  }, [loggingIn, meetingsSynced, showPhraseOptions, tapId, userInfo]);

  const scrollToTranscript = useCallback((condition) => {
    if (
      condition &&
      transcriptContainerRef.current &&
      transcriptContainerRef.current.offsetTop > window.innerHeight * 0.85
    ) {
      window.scrollTo({
        top: transcriptContainerRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    searchingPhrasesRef.current = searchingPhrases;
    scrollToTranscript(searchingPhrases);
    if (!searchingPhrases) {
      setSelectedText(null);
    }
  }, [scrollToTranscript, searchingPhrases]);

  useEffect(() => {
    scrollToTranscript(selectedText?.text);
  }, [scrollToTranscript, selectedText]);

  // const downloadTapestryImage = useCallback(async () => {
  //   const link = document.createElement("a");
  //   link.download = `${tapestryData?.title.replace(/[^a-z0-9]/gi, "_")}.png`;
  //   const href = await AppBackService.getTapestryImageDummy(userInfo.username, documentID);
  //   link.href = dataURI(href);
  //   link.style.visibility = "hidden";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }, [tapestryData, userInfo]);

  // const submitDocSetup = useCallback(async () => {
  //   if (documentSetup !== documentSetupRef.current) {
  //     setLoading(true);
  //     const updatedTapestry = await AppBackService.updateTapestryDummy(userInfo.username, {
  //       documentSetup,
  //     });
  //     if (updatedTapestry.error) {
  //       setDocumentSetup(documentSetupRef.current);
  //       showSnackbar("Unable to update tapestry", "error");
  //     } else {
  //       documentSetupRef.current = documentSetup;
  //       setTapestryData(updatedTapestry);
  //       showSnackbar("Tapestry updated", "success");
  //     }
  //     setLoading(false);
  //   }
  // }, [documentSetup, showSnackbar, userInfo]);

  // editing

  useEffect(() => {
    async function fetchBackgrounds() {
      // fetch backgrounds
      const bgs = await AppBackService.getBackgroundsByLayout(activeLayout);
      if (bgs) {
        setBackgrounds((backgrounds) => ({
          ...backgrounds,
          [activeLayout]: bgs.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {}),
        }));
      }
    }
    if (editingTapestry && !backgroundsRef.current[activeLayout]) {
      fetchBackgrounds();
    }
  }, [activeLayout, editingTapestry]);

  const changeBackground = useCallback(
    async (backgroundID) => {
      if (backgrounds[activeLayout] && backgrounds[activeLayout][backgroundID]) {
        // update the SVG background
        const imageEl = tapestryContainer.current?.querySelector("image.background-image");
        if (imageEl) {
          imageEl.setAttribute("href", backgrounds[activeLayout][backgroundID].imageUrl);
        }
        setActiveBackground(backgroundID);
        // post the update
        await AppBackService.updateUserTap(userInfo.username, tapId, {
          tap_edits: { background_id: backgroundID },
        });
      }
    },
    [activeLayout, backgrounds, tapId, userInfo]
  );

  const updatePhrase = useCallback(
    async (eventID, index) => {
      // update which phrase option is selected
      setTextpathOptions((textpathOptions) => {
        const textpathOptionsCopy = { ...textpathOptions };
        textpathOptionsCopy[activeLayoutRef.current][eventID].options.forEach((tpo, i) => {
          tpo.selected = i === index;
        });
        textpathOptionsRef.current = textpathOptionsCopy;
        return textpathOptionsCopy;
      });
      const selectedPhrase = textpathOptionsRef.current[activeLayoutRef.current][eventID].options[index];
      setUpdatedPhrase({ eventID, selectedPhrase });
      setPhraseOptions(null);
      // post the update
      await AppBackService.updateUserTap(userInfo.username, tapId, {
        tap_edits: { phrase_edits: { [eventID]: selectedPhrase.textContent } },
      });
    },
    [tapId, userInfo]
  );

  const updateTitle = useCallback(
    async (newTitle) => {
      if (newTitle !== tapestryData.title) {
        setTapestryData((tapestryData) => {
          const dataCopy = { ...tapestryData };
          dataCopy.title = newTitle;
          return dataCopy;
        });
        // update the SVG title
        const dateRegex = /\s\d{1,2}\/\d{1,2}\/\d{2,4}$/;
        const tspan = tapestryContainer.current?.querySelector(".tap-title tspan");
        if (tspan) {
          const titleMatch = tspan.textContent.match(dateRegex);
          tspan.textContent = newTitle + (titleMatch[0] || "");
        } else {
          // search for title without .tap-title class
          for (const rootChild of tapestryContainer.current.querySelector("svg").childNodes) {
            const childNodes = [...rootChild.childNodes];
            if (rootChild.nodeName === "g" && childNodes.every((child) => child.nodeName === "text")) {
              const titleNode = childNodes.find((child) => dateRegex.test(child.querySelector("tspan")?.textContent));
              if (titleNode) {
                const tspan = titleNode.querySelector("tspan");
                const titleMatch = tspan.textContent.match(dateRegex);
                tspan.textContent = newTitle + (titleMatch[0] || "");
              }
            }
          }
        }
        // post the update
        await AppBackService.updateUserTap(userInfo.username, tapId, {
          tap_edits: { title: newTitle },
        });
      }
    },
    [tapId, tapestryData, userInfo]
  );

  // elements

  const transcript = useMemo(
    () => (
      <Box className="transcript-container" ref={transcriptContainerRef}>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }} className="transcript-heading">
          Transcript
        </Typography>
        <DocumentText docContent={doc.content} selectedText={selectedText} />
      </Box>
    ),
    [doc, selectedText]
  );

  const phraseOptionsEl = useMemo(
    () =>
      phraseOptions ? (
        <Box
          sx={{
            width: "350px",
            background: "#ffffff",
            boxShadow: "0px 2px 6px #999999;",
            padding: "16px",
            paddingTop: "14px",
            borderRadius: "4px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
          className="phrase-select">
          <Box sx={{ height: "32px" }} className="phrase-select-header">
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: "14px",
                color: "#444444",
                float: "left",
                fontWeight: "bold",
              }}
              variant="h3">
              Select phrase
            </Typography>
            <CloseIcon
              sx={{
                float: "right",
                color: "#666666",
                cursor: "pointer",
              }}
              onClick={() => setPhraseOptions(null)}
            />
          </Box>
          <Box sx={{ borderRadius: "3px", border: "1px solid #169697" }} className="phrase-options">
            {phraseOptions.options.slice(0, NUM_PHRASE_OPTIONS).map((phraseOption, i) => (
              <Box
                key={uuid()}
                sx={{
                  padding: "10px",
                  fontSize: "14px",
                  cursor: "pointer",
                  textAlign: "left",
                }}
                onClick={() => updatePhrase(phraseOptions.eventID, i)}
                className={classNames("phrase-option", { selected: phraseOption.selected })}>
                {phraseOption.textContent}
              </Box>
            ))}
          </Box>
        </Box>
      ) : null,
    [phraseOptions, updatePhrase]
  );

  const tapestry = useMemo(
    () => (
      <Box
        className={classNames("edit-tapestry", {
          "un-editable": !editingTapestry && !searchingPhrases,
          "editing-phrases": editingTapestry,
          "searching-phrases": searchingPhrases,
        })}
        sx={{
          padding: "10px",
          paddingBottom: editingTapestry ? "20px" : "53px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          textAlign: "left",
          boxSizing: "border-box",
        }}>
        {!loading && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", marginBottom: "10px", position: "relative" }} className="tapestry-title">
              {title && (
                <InlineEdit
                  value={title}
                  setValue={setTitle}
                  submit={updateTitle}
                  textStyle={{ fontSize: "24px", fontWeight: "bold" }}
                  enterSubmit
                />
              )}
              {tapestryData.meetingDate && (
                <Typography sx={{ display: "inline-block", verticalAlign: "baseline", marginLeft: "10px" }}>
                  {dayjs(
                    isNaN(+tapestryData.meetingDate) ? tapestryData.meetingDate : +tapestryData.meetingDate
                  ).format("l")}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                marginBottom: "20px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
              className="tapestry-subtitle">
              {/* <Box sx={{ width: "calc(100% - 420px)" }}>
                <InlineEdit
                  value={documentSetup}
                  setValue={setDocumentSetup}
                  submit={submitDocSetup}
                  placeholder="Describe the purpose or agenda of this meeting"
                />
              </Box> */}
              <Box
                className="tapestry-actions"
                sx={{ whiteSpace: "nowrap", display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
                <Box
                  className="tapestry-action"
                  sx={{ ...tapestryActionStyle, marginLeft: "0" }}
                  onClick={() => {
                    setSearchingPhrases(false);
                    setEditingTapestry((editingTapestry) => !editingTapestry);
                  }}>
                  <img
                    style={{ ...alignMiddleStyle, height: 12 }}
                    className="pointer-icon"
                    src={pointerIcon}
                    alt=""
                    draggable="false"
                  />
                  <Typography sx={{ color: editingTapestry ? primaryGreen : undefined }}>Edit Tapestry</Typography>
                </Box>
                <Box
                  className="tapestry-action"
                  sx={tapestryActionStyle}
                  onClick={() => {
                    setEditingTapestry(false);
                    setSearchingPhrases((searchingPhrases) => !searchingPhrases);
                  }}>
                  <ChatIcon sx={{ ...alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                  <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>View transcript</Typography>
                </Box>
                <Box className="tapestry-action" sx={tapestryActionStyle} onClick={deleteTap}>
                  <CancelIcon sx={{ ...alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                  <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>Delete Tapestry</Typography>
                </Box>
                <Box className="tapestry-action" sx={tapestryActionStyle} onClick={handleModalOpen}>
                  <IosShareIcon sx={{ ...alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                  <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>Share Tapestry</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: searchingPhrases && window.innerWidth > breakpoint ? tapestryHeight : "auto",
            position: "relative",
          }}
          className={classNames("edit-tapestry-container", { "view-transcript": searchingPhrases })}
          ref={tapestryContainer}>
          {!svg[activeLayout] || loading ? (
            <CircularProgress size={60} sx={{ margin: "40px auto" }} />
          ) : (
            <TapestryView
              svg={svg[activeLayout]}
              updatedPhrase={updatedPhrase}
              width={searchingPhrases && window.innerWidth > breakpoint ? 0.75 : 1}
            />
          )}
          {searchingPhrases && doc.content && transcript}
          {phraseOptionsEl}
        </Box>
      </Box>
    ),
    [
      editingTapestry,
      searchingPhrases,
      title,
      updateTitle,
      tapestryData.meetingDate,
      deleteTap,
      handleModalOpen,
      tapestryHeight,
      svg,
      activeLayout,
      loading,
      updatedPhrase,
      doc.content,
      transcript,
      phraseOptionsEl,
    ]
  );

  const panelSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Skeleton key={i} variant="rectangular" width={190} height={120} sx={thumbnailStyle} />
      )),
    []
  );

  // alternative backgrounds and layouts
  const editPanelElement = useMemo(
    () => (
      <Box
        className="edit-panel"
        sx={{ width: "100%", boxSizing: "border-box", paddingLeft: "30px", marginBottom: "72px" }}>
        <Box sx={editPanelStyle}>
          <Typography sx={editPanelHeaderStyle}>Alternative Backgrounds</Typography>
          <Box sx={{ display: "flex" }}>
            {backgrounds[activeLayout] ? (
              backgrounds[activeLayout].error ? (
                <ErrorIcon />
              ) : (
                Object.keys(backgrounds[activeLayout])
                  .filter((bgId) => bgId !== activeBackground)
                  .map((bgId) => (
                    <img
                      className="edit-panel-option"
                      key={bgId}
                      style={{ ...thumbnailStyle, cursor: "pointer" }}
                      src={backgrounds[activeLayout][bgId].imageUrl}
                      alt={backgrounds[activeLayout][bgId].description}
                      onClick={() => changeBackground(bgId)}
                      draggable="false"
                    />
                  ))
              )
            ) : (
              panelSkeleton
            )}
          </Box>
        </Box>
      </Box>
    ),
    [activeBackground, activeLayout, backgrounds, changeBackground, panelSkeleton]
  );

  // TODO: we should have a custom error handling message component
  const errorEl = useMemo(
    () => (
      <Box className="error">
        <ErrorIcon />
      </Box>
    ),
    []
  );

  if (!tapId || redirect) {
    return <Navigate to="/tapestries" />;
  }
  if (error) {
    return errorEl;
  }
  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
      }}
      id="detail-page-container">
      {tapestry}
      {editingTapestry && editPanelElement}
      {deleteTapConfirm}
      {snackbarEl}
      <ShareModal open={modalOpen} handleClose={handleModalClose} tapShareUrl={imageURI} title={tapestryData.title} />
    </Box>
  );
};

function phrasesData(textpathOptions) {
  const data = {};
  for (const tpo of Object.values(textpathOptions)) {
    for (const option of tpo.options) {
      if (option.indexes) {
        data[option.textContent] = option.indexes;
      }
    }
  }
  return data;
}

export default DetailPage;
