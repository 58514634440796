import { Box } from "@mui/material";
import { useMemo } from "react";
import { useRoutes } from "../app/routes";

const styles = {
  scrollBoxStyle: {
    height: "100vh",
    overflowY: "scroll",
    background: "#F1F9FB",
  },
  innerBoxStyle: {
    borderRadius: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  logoImageStyle: { paddingTop: "15px" },
};

const MobileIdentity = () => {
  const { unAuthRoutes } = useRoutes();
  const res = useMemo(
    () => (
      <div style={styles.scrollBoxStyle}>
        <Box sx={styles.innerBoxStyle}>
          <img src="/logo_with_text.png" style={styles.logoImageStyle} alt="Tapestry AI logo" />
          {unAuthRoutes()}
        </Box>
      </div>
    ),
    [unAuthRoutes]
  );

  return res;
};

export default MobileIdentity;
