import React, { useEffect, useState, useMemo } from "react";
import { Auth } from "aws-amplify";
import { Grid } from "@mui/material";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
// import TrustedDevices from "../settings.99/TrustedDevices";

const styles = { gridContainerStyle: { marginLeft: "30px" } };

const Securitycenter = () => {
  const [userData, setUserData] = useState(null);
  const [width, setWindowWidth] = useState(0);

  const isMobile = useMemo(() => width <= 1023, [width]);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    async function getData() {
      const data = await Auth.currentAuthenticatedUser();
      setUserData(data);
    }
    getData();
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <ChangeEmail userData={userData} />
          <br />
          <br />
          <ChangePassword userData={userData} />
          <br />
        </>
      ) : (
        <div className="security-center">
          <Grid container spacing={3} sx={styles.gridContainerStyle}>
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <ChangeEmail userData={userData} />
              <br />
            </Grid>
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <ChangePassword userData={userData} />
            </Grid>
            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Securitycenter;
