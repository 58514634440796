import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { v4 as uuid } from "uuid";

export default function DocumentText({ docContent, selectedText, textContainer }) {
  const [content, setContent] = useState(docContent);
  const contentContainer = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (selectedText?.text && selectedText.text.length > 1) {
      let indexes = selectedText.indexes ? [selectedText.indexes] : getIndexesOf(selectedText.text, docContent);
      if (indexes.length) {
        const pieces = [];
        for (let i = 0; i < indexes.length; i++) {
          const lastIndex = i > 0 ? indexes[i - 1].end : 0;
          pieces.push(docContent.slice(lastIndex, indexes[i].start));
          pieces.push(
            <span style={{ background: "#169697", color: "#ffffff" }} className="highlighted" key={uuid()}>
              {docContent.slice(indexes[i].start, indexes[i].end)}
            </span>
          );
        }
        pieces.push(docContent.slice(indexes[indexes.length - 1].end));
        setContent(pieces);
      }
    } else {
      setContent(docContent);
    }

    function getIndexesOf(searchStr, str, caseSensitive = false) {
      if (searchStr.length === 0) {
        return [];
      }
      let startIndex = 0;
      let index;
      const indexes = [];
      if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indexes.push({ start: index, end: index + searchStr.length });
        startIndex = index + searchStr.length;
      }
      return indexes;
    }
  }, [docContent, selectedText]);

  // scroll to first highlight
  useEffect(() => {
    const container = textContainer?.current ? textContainer : containerRef;
    if (contentContainer.current && content && container.current) {
      const highlight = contentContainer.current.querySelector(".highlighted");
      if (highlight) {
        container.current.scrollTo({
          top: highlight.offsetTop - container.current.offsetTop - 100,
          behavior: "smooth",
        });
      }
    }
  }, [content, textContainer]);

  return (
    <Box
      sx={{ flexGrow: "1", overflowY: "auto", border: "1px solid #169697" }}
      className="doc-container"
      ref={containerRef}>
      <Box
        sx={{
          fontSize: "18px",
          lineHeight: "22px",
          width: "calc(100% - 10px)",
          padding: "10px",
          overflowX: "auto",
          boxSizing: "border-box",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
        className="doc-content"
        ref={contentContainer}>
        {content}
      </Box>
    </Box>
  );
}
DocumentText.propTypes = {
  docContent: PropTypes.string,
  selectedText: PropTypes.object,
  textContainer: PropTypes.object,
};
