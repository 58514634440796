import { useCallback } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Login from "../identity/Login";
import SignUp from "../identity/SignUp";
import ForgotPassword from "../identity/ForgotPassword";
import Settings from "../settings/Setting";
import EmailVerify from "../identity/EmailVerify";
import ZoomRedirect from "../pages/ZoomRedirect";
import TapestriesPage from "../pages/TapestriesPage";
import Timeline from "../pages/Timeline";
import DocumentsPage from "../pages/DocumentsPage";
import DocumentPage from "../pages/DocumentPage";
import DetailPage from "../pages/DetailPage";
import EarlyAccessPage from "../pages/EarlyAccessPage";

function useRoutes() {
  let location = useLocation();
  let meetingsWithQueryParams = "/meetings" + location.search;

  const unAuthRoutes = useCallback(() => {
    return (
      <Routes>
        <Route exact path="/early" element={<EarlyAccessPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/email_verify" element={<EmailVerify />} />
        <Route exact path="/zoom" element={<ZoomRedirect />} />
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
      </Routes>
    );
  }, []);

  const accountActiveRoutes = useCallback((redirect) => {
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to={meetingsWithQueryParams} />} />
        <Route exact path="/login" element={<Navigate to={meetingsWithQueryParams} />} />
        <Route exact path="/signup" element={<Navigate to={meetingsWithQueryParams} />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/tapestries" element={<TapestriesPage />} />
        <Route exact path="/meetings" element={<Timeline />} />
        <Route exact path="/tapestry/:tapId" element={<DetailPage />} />
        <Route exact path="/documents" element={<DocumentsPage />} />
        <Route exact path="/document/:docId" element={<DocumentPage />} />
        <Route exact path="/zoom" element={<ZoomRedirect loggedIn />} />
        <Route exact path="*" element={<Navigate to={`${"/"}`} />} />
      </Routes>
    );
  }, []);

  const testAccountRoutes = useCallback((redirect, accountType) => {
    return (
      <Routes>
        <Route exact path="/tapestries" element={<TapestriesPage />} />
        <Route exact path="/meetings" element={<Timeline />} />
        <Route exact path="/settings" element={<Settings />} />
      </Routes>
    );
  }, []);

  return { unAuthRoutes, accountActiveRoutes, testAccountRoutes };
}

export { useRoutes };
