import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as yup from "yup";

export const passwordValidation = () => {
  return yup
    .string("Enter your password")
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[/\W|_/g]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    );
};

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      maxWidth: 300,
      padding: 20,
      border: "1px solid #dadde9",
    },
  })
);

const PasswordComponent = ({
  label,
  name,
  type,
  onChange,
  onMouseDown,
  onClick,
  value,
  error,
  helperText,
  ...props
}) => {
  const [values, setValues] = useState({
    letters: "cancel.svg",
    capital_letter: "cancel.svg",
    lower_letter: "cancel.svg",
    number: "cancel.svg",
    symbol: "cancel.svg",
    confirmletters: "cancel.svg",
    confirmcapital_letter: "cancel.svg",
    confirmlower_letter: "cancel.svg",
    confirmnumber: "cancel.svg",
    confirmsymbol: "cancel.svg",
  });
  const updateToolTip = () => {
    const upperCaseLetters = /[A-Z]/g;
    const lowerCaseLetters = /[a-z]/g;

    if (upperCaseLetters.test(value)) {
      setValues({
        ...values,
        capital_letter: "checkmark.svg",
      });
      values.capital_letter = "checkmark.svg";
    } else {
      setValues({
        ...values,
        capital_letter: "cancel.svg",
      });
      values.capital_letter = "cancel.svg";
    }
    if (lowerCaseLetters.test(value)) {
      setValues({
        ...values,
        lower_letter: "checkmark.svg",
      });
      values.lower_letter = "checkmark.svg";
    } else {
      setValues({
        ...values,
        lower_letter: "cancel.svg",
      });
      values.lower_letter = "cancel.svg";
    }

    if (value.match(/[0-9]/g)) {
      setValues({
        ...values,
        number: "checkmark.svg",
      });
      values.number = "checkmark.svg";
    } else {
      setValues({
        ...values,
        number: "cancel.svg",
      });
      values.number = "cancel.svg";
    }
    if (value.match(/\W|_/g)) {
      setValues({
        ...values,
        symbol: "checkmark.svg",
      });
      values.symbol = "checkmark.svg";
    } else {
      setValues({
        ...values,
        symbol: "cancel.svg",
      });
      values.symbol = "cancel.svg";
    }
    if (value.length > 7) {
      setValues({
        ...values,
        letters: "checkmark.svg",
      });
      values.letters = "checkmark.svg";
    } else {
      setValues({
        ...values,
        letters: "cancel.svg",
      });
      values.letters = "cancel.svg";
    }
  };
  const updateConfirmToolTip = () => {
    const upperCaseLetters = /[A-Z]/g;
    const lowerCaseLetters = /[a-z]/g;

    if (upperCaseLetters.test(value)) {
      setValues({
        ...values,
        confirmcapital_letter: "checkmark.svg",
      });
      values.confirmcapital_letter = "checkmark.svg";
    } else {
      setValues({
        ...values,
        confirmcapital_letter: "cancel.svg",
      });
      values.confirmcapital_letter = "cancel.svg";
    }
    if (lowerCaseLetters.test(value)) {
      setValues({
        ...values,
        confirmlower_letter: "checkmark.svg",
      });
      values.confirmlower_letter = "checkmark.svg";
    } else {
      setValues({
        ...values,
        confirmlower_letter: "cancel.svg",
      });
      values.confirmlower_letter = "cancel.svg";
    }

    if (value.match(/[0-9]/g)) {
      setValues({
        ...values,
        confirmnumber: "checkmark.svg",
      });
      values.confirmnumber = "checkmark.svg";
    } else {
      setValues({
        ...values,
        confirmnumber: "cancel.svg",
      });
      values.confirmnumber = "cancel.svg";
    }
    if (value.match(/\W|_/g)) {
      setValues({
        ...values,
        confirmsymbol: "checkmark.svg",
      });
      values.confirmsymbol = "checkmark.svg";
    } else {
      setValues({
        ...values,
        confirmsymbol: "cancel.svg",
      });
      values.confirmsymbol = "cancel.svg";
    }
    if (value.length > 7) {
      setValues({
        ...values,
        confirmletters: "checkmark.svg",
      });
      values.confirmletters = "checkmark.svg";
    } else {
      setValues({
        ...values,
        confirmletters: "cancel.svg",
      });
      values.confirmletters = "cancel.svg";
    }
  };

  useEffect(() => {
    if (name === "confirm_password") {
      updateConfirmToolTip();
    } else {
      updateToolTip();
    }
  }, [value]);

  return (
    <>
      <HtmlTooltip
        arrow
        placement="bottom"
        disableFocusListener
        disableTouchListener
        title={
          <>
            <Typography className="Typography" color="inherit">
              Your password must have at least:{" "}
            </Typography>
            <img src={name === "confirm_password" ? values.confirmletters : values.letters} alt="check" width="18px" />
            &nbsp;&nbsp;8 letters
            <br />
            <img
              src={name === "confirm_password" ? values.confirmcapital_letter : values.capital_letter}
              alt="check"
              width="18px"
            />
            &nbsp;&nbsp;one capital letter
            <br />
            <img
              src={name === "confirm_password" ? values.confirmlower_letter : values.lower_letter}
              alt="check"
              width="18px"
            />
            &nbsp;&nbsp;one lower letter
            <br />
            <img src={name === "confirm_password" ? values.confirmnumber : values.number} alt="check" width="18px" />
            &nbsp;&nbsp;one number
            <br />
            <img src={name === "confirm_password" ? values.confirmsymbol : values.symbol} alt="check" width="18px" />
            &nbsp;&nbsp;one symbol
            <br />
          </>
        }>
        <TextField
          variant="outlined"
          sx={props.style}
          id={name}
          name={name}
          label={label}
          type={type}
          value={value}
          onChange={onChange}
          error={error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={onClick} onMouseDown={onMouseDown}>
                  {props.values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </HtmlTooltip>
    </>
  );
};

PasswordComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "password"]),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
export default PasswordComponent;
