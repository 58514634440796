/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsconfig = {
//   aws_project_region: "us-west-2",
//   aws_cognito_region: "us-west-2",
//   aws_user_pools_id: "us-west-2_NCODXYKnQ",
//   aws_user_pools_web_client_id: "6jce60lt3m2hjrqlfvn64mmurh",
//   oauth: {},
// };

const awsconfig = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  // Dev
  //aws_user_pools_id: "us-west-2_GYo3Ypird",
  //aws_user_pools_web_client_id: "2v5rr9advjaqlpkg1vf4905vgb",
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT,
  oauth: {},
};

export default awsconfig;
