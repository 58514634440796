import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { Box, Typography, Grid, Skeleton, Button } from "@mui/material";
import { RootContext } from "../context/root-provider";
import AppBackService from "../services/appback";
import { useSnackbar } from "../components/AlertNotification";
import { useFilter } from "../services/hooks";
import dayjs from "dayjs";
import TapestryCard from "../components/TapestryCard";
import BlankTapestryImage from "../assets/blank_tapestry.png";
import "./TapestriesPage.scss";

// const PAGE_SIZE = 12;

const styles = {
  outerBoxStyle: {
    padding: "64px",
    paddingTop: "32px",
    height: "calc(100vh - 106px)",
    boxSizing: "border-box",
    position: "relative",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
  headerBoxStyle: { height: "36px", marginBottom: "32px", display: "flex", alignItems: "center" },
  headerStyle: {
    fontSize: "28px",
    lineHeight: "36px",
    color: "#051817",
    fontWeight: "700",
    float: "left",
    marginRight: "16px",
  },
  noDataHeaderStyle: {
    fontSize: "36px",
    lineHeight: "44px",
    color: "#051817",
    fontWeight: "700",
  },
  noDataSubtitleStyle: {
    fontSize: "24px",
    lineHeight: "28px",
    color: "#051817",
    fontWeight: "700",
    float: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "-20px",
  },
  createTapButtonStyle: {
    backgroundColor: "rgba(0, 150, 152, 1)",
    color: "white",
    marginTop: "25px",
    "&:hover": { backgroundColor: "rgb(0 96 98)" },
  },
  blankTapImageContainerStyle: { justifyContent: "center", textAlign: "center", display: "flex", marginTop: "-40px" },
  blankTapImageStyle: { paddingTop: "20px", maxWidth: "400px" },
};

function dateObj(date) {
  return dayjs(isNaN(+date) ? date : +date);
}

function formatDate(date) {
  return dateObj(date).format("MMMM Do, YYYY [at] h:mma");
}

const TapestriesPage = () => {
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tapestriesData, setTapestriesData] = useState([]);
  // const [moreTapestries, setMoreTapestries] = useState(true);

  const { filter, filterEl } = useFilter("Filter Tapestries");

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();

  useEffect(() => {
    setSelectedTab("tapestries");
  }, [setSelectedTab]);

  const fetchTapestries = useCallback(async () => {
    setLoading(true);
    //initialize tapestries
    let tapestries = [];
    tapestries = await AppBackService.getUserTaps(userInfo.username);

    if (tapestries.taps != null) {
      // if (tapestries.taps.length < PAGE_SIZE) {
      //   setMoreTapestries(false);
      // }

      // if (page !== 1 && tapestries.taps.length) {
      //   setTapestriesData((t) => [...t, ...tapestries.taps]);
      // } else if (tapestries.taps.length) {
      //   setTapestriesData([...tapestries.taps]);
      // }
      if (tapestries.taps.length) {
        setTapestriesData([...tapestries.taps]);
      }
      setLoading(false);
    }
  }, [userInfo]);

  // init, fetch first page of tapestries
  useEffect(() => {
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchTapestries();
    }
  }, [fetchTapestries, loggingIn, meetingsSynced, userInfo]);

  // const nextPageOfTapestries = useCallback(() => {
  //   setPage((p) => p + 1);
  // }, []);

  const resolveDelete = useCallback(
    (tapId) => {
      if (tapId) {
        setTapestriesData((tapestriesData) => tapestriesData.filter((td) => td.tapId !== tapId));
        showSnackbar("Tapestry deleted", "success");
      } else {
        showSnackbar("Unable to delete Tapestry", "error");
      }
    },
    [showSnackbar]
  );

  const header = useMemo(
    () => (
      <Box sx={styles.headerBoxStyle}>
        <Typography sx={styles.headerStyle} variant="h1">
          Tapestries
        </Typography>
        {filterEl}
      </Box>
    ),
    [filterEl]
  );

  const tapestrySkeletons = useMemo(
    () =>
      Array.from(new Array(4)).map((_d, i) => (
        <Grid item sx={{ width: "306px", marginRight: "24px" }} key={i}>
          <Skeleton variant="rectangular" width={306} height={185} />
          <Skeleton sx={{ marginTop: "8px" }} />
          <Skeleton width="60%" sx={{ marginTop: "4px" }} />
        </Grid>
      )),
    []
  );

  const tapestrySkeletonsPage = useMemo(
    () => (
      <Box>
        {header}
        <Grid container spacing="24px">
          {tapestrySkeletons}
        </Grid>
      </Box>
    ),
    [header, tapestrySkeletons]
  );

  const tapestryCards = useMemo(() => {
    const cards = tapestriesData
      .filter(
        (tapestry) =>
          !filter ||
          tapestry.title.toLowerCase().includes(filter.toLowerCase()) ||
          formatDate(tapestry.meetingDate).toLowerCase().includes(filter.toLowerCase())
      )
      .sort((a, b) => dateObj(b.meetingDate) - dateObj(a.meetingDate))
      .map((tapestry) => (
        <TapestryCard
          key={tapestry.tapId}
          tapId={tapestry.tapId}
          title={tapestry.title}
          date={formatDate(tapestry.meetingDate)}
          thumbnail={tapestry.thumbnailName}
          state="generated"
          resolveDelete={resolveDelete}
        />
      ));
    return cards;
  }, [tapestriesData, filter, resolveDelete]);

  const noDataPage = useMemo(
    () => (
      <Grid container>
        <Grid item xs={0} sm={2} md={3} lg={3} />
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Typography sx={styles.noDataHeaderStyle}>Ready to create your first Tapestry?</Typography>
          <div style={styles.blankTapImageContainerStyle}>
            <img src={BlankTapestryImage} style={styles.blankTapImageStyle} alt="calendar" />
          </div>
          <Typography sx={styles.noDataSubtitleStyle}>
            Record a meeting in Zoom to create Tapestries from your discussion
          </Typography>
          <Button sx={styles.createTapButtonStyle}>Create Tapestry</Button>
        </Grid>
        <Grid item xs={0} sm={2} md={3} lg={3} />
      </Grid>
    ),
    []
  );

  const res = useMemo(
    () => (
      <Box
        sx={styles.outerBoxStyle}
        // onScroll={(e) => {
        //   // fetch next page of tapestries if scrolled to bottom
        //   if (e.target.scrollTop === e.target.scrollHeight - (window.innerHeight - 106) && moreTapestries) {
        //     nextPageOfTapestries();
        //   }
        // }}
      >
        {loading ? (
          tapestrySkeletonsPage
        ) : tapestriesData.length ? (
          <Box>
            {header}
            <Grid container spacing="24px">
              {tapestryCards}
            </Grid>
          </Box>
        ) : (
          noDataPage
        )}
        {snackbarEl}
      </Box>
    ),
    [loading, tapestrySkeletonsPage, tapestriesData, header, tapestryCards, noDataPage, snackbarEl]
  );

  return res;
};

export default TapestriesPage;
